import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const BadgeManagement: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="badge-management">
            <button onClick={() => {
                navigate('/badge/create')
            }}>Create</button>

            <h1>Badge management works!</h1>
        </div>
    )
};

export default BadgeManagement;
