/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { MdClose } from 'react-icons/md';
import searchIcon from '../../assets/icons/search.svg';
import { debounce } from 'lodash';

type Props = {
    initState?: string;
    reset?: number;
    onChange: (value: string) => void;
};

const SearchBar: React.FC<Props> = ({ initState, onChange, reset }) => {
    const [state, setState] = useState(initState || '');

    const debounceQueryChange = useCallback(
        debounce((value) => onChange(value), 1000),
        []
    );

    const handleQueryChange = (value: string) => {
        setState(value);
        debounceQueryChange(value);
    };

    useEffect(() => {
        if (reset) {
            setState('');
        }
    }, [reset]);

    return (
        <div className="search-bar">
            <input
                className="search-bar__input"
                placeholder="Search"
                value={state}
                onChange={(e) => handleQueryChange(e.target.value)}
            />
            {state && (
                <MdClose
                    className="search-bar__btn-clear"
                    onClick={() => handleQueryChange('')}
                />
            )}
            <img
                src={searchIcon}
                alt="search-icon"
                className="search-bar__btn-search"
            />
        </div>
    );
};

export default SearchBar;
