/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { Modal, Slider, Button } from 'antd';
import { RedoOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toastError } from '../../../layouts/MainLayout/slice';

const SUPPORT_IMAGE_FILE = ['image/jpeg', 'image/jpg', 'image/png'];

const CropImageModal = (props: any) => {
    const { isModalVisible, handleCancel, type, updateImage, isLoading } = props;
    const dispatch = useDispatch();
    const fileRef = useRef<any>();
    const cropperRef = useRef<any>();
    const [imageToCrop, setImageToCrop] = useState();
    const [zoom, setZoom] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [fileImage, setFileImage] = useState();
    const [croppedImageUrl, setCroppedImageUrl] = useState('');

    const onZoomSliderChange = (value: any) => {
        setZoom(value);
        cropperRef.current.cropper.zoomTo(value);
    }
    
    const onRotateSliderChange = (value: any) => {
        setRotation(value);
        cropperRef.current.cropper.rotateTo(value);
    }
    
    const resetImage = () => {
        setZoom(0);
        setRotation(0);
        cropperRef.current.cropper.zoomTo(0.25);
        cropperRef.current.cropper.rotateTo(0);
    }

    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          if (SUPPORT_IMAGE_FILE.find(el => el === file.type)) {
            setFileImage(file);
            let imageDataUrl: any = await readFile(file);
            setImageToCrop(imageDataUrl);
            e.target.value = '';
          }
          else {
            handleCancel();
            dispatch(toastError({ message: 'This file type is not supported. You can only upload JPG/PNG/JPEG file!' }));
            e.target.value = '';
          }
        }
    }

    const readFile = (file: any) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        })
    }

    const getCroppedImage = (fileImage: any) => {
        return new Promise((resolve) => {
          cropperRef.current.cropper.getCroppedCanvas().toBlob((blob: any) => {
            blob.name = fileImage.name;
            const file = new File([blob], fileImage.name, { type: fileImage.type })
            // window.URL.revokeObjectURL(fileUrl);
            const fileUrl = window.URL.createObjectURL(blob);
    
            resolve({ fileUrl, file });
          }, fileImage.type)
        })
    }

    const showCroppedImage = useCallback(async () => {
        if (!imageToCrop) {
            updateImage(null)
            handleCancel();
        }
        else {
            const croppedImage = await getCroppedImage(fileImage);
            updateImage(croppedImage);
            setCroppedImageUrl((croppedImage as any)?.fileUrl);
        }
    }, [imageToCrop])

    const clearImage = () => {
        setImageToCrop(undefined);
        setFileImage(undefined);
        updateImage(null);
        resetImage();
    }

    const handleCancelClick = () => {
        if (!croppedImageUrl) {
            setImageToCrop(undefined);
        }

        handleCancel();
    };

    const ModalFooter = () => (
        <div className="modal-footer">
            <button className="btn-default btn-delete-image" onClick={clearImage} disabled={!imageToCrop}>Delete Photo</button>
            <div className="footer-right">
                <button className="btn-default" onClick={() => fileRef.current.click()}>Upload Photo</button>
                <Button className="btn-submit" onClick={showCroppedImage} loading={isLoading}>Apply</Button>
            </div>
        </div>
    )

    return (
        <Modal
            title="Crop Image"
            visible={isModalVisible}
            onCancel={handleCancelClick}
            centered
            maskClosable={false}
            className="crop-image-modal"
            footer={[<ModalFooter />]}
        >
            <input
                type="file"
                ref={fileRef}
                onChange={onFileChange}
                accept="image/*"
                style={{ display: "none" }}
            />
            {imageToCrop ? (
                <Cropper
                    style={{ height: 400, width: "100%" }}
                    aspectRatio={type === 'background' ? 270 / 128 : 1}
                    src={imageToCrop}
                    ref={cropperRef}
                    zoomOnWheel={false}
                    zoomOnTouch={false}
                    viewMode={1}
                    background={false}
                    guides={true}
                    className={type === 'avatar' ? 'cropper-avatar' : ''}
                    // checkCrossOrigin={false}
                    // checkOrientation={false}
                    // crossOrigin="anonymous"
                />
            ): (
                <div style={{ height: 400, width: "100%" }}></div>
            )}
            <div className="slide-container">
                <div className="slide-item">
                    <div className="slide-label">Zoom</div>
                    <Slider
                        min={0}
                        max={2}
                        step={0.05}
                        value={zoom}
                        onChange={onZoomSliderChange}
                        tooltipVisible={false}
                        disabled={!imageToCrop}
                    />
                </div>
                <div className="slide-item">
                    <div className="slide-label">Rotate</div>
                    <Slider
                        min={0}
                        max={360}
                        step={1}
                        value={rotation}
                        onChange={onRotateSliderChange}
                        tooltipVisible={false}
                        disabled={!imageToCrop}
                    />
                </div>
                <div className="slide-item-reset">
                    <div className="reset-label">Reset</div>
                    <RedoOutlined
                        style={{ fontSize: 24, color: "#fff" }}
                        onClick={resetImage}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default CropImageModal
