import * as yup from "yup";

export const nftSchema = yup.object().shape({
  putOnMarketPlace: yup.boolean(),
  price: yup.string().when("putOnMarketPlace", {
    is: true,
    then: yup
      .string()
      .required("This field is required")
      .test({
        test: (val) => Number(val) > 0,
        message: "Price must be greater than 0",
        name: "min",
      }),
  }),
  unlockOncePurchasedFlag: yup.boolean(),
  unlockOncePurchased: yup.string().when("unlockOncePurchasedFlag", {
    is: true,
    then: yup
      .string()
      .test({
        test: (val) => !!val?.trim(),
        message: "This field is required",
      }),
  }),
  name: yup
    .string()
    .test({ test: (val) => !!val?.trim(), message: "This field is required" }),
  royalties: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) <= 50,
      message: "The royalties must be less than or equal to 50",
      name: "max",
    }),
  amount: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) > 0,
      message: "The number of copies must be greater than 0",
      name: "min",
    }),
  categories: yup.array().test({
    test: (val) => !!val?.length,
    message: "This field is required",
  }),
  creatorId: yup.number().required("This field is required"),
  tags: yup
    .array()
    .test({
      test: (val) => !!val?.length,
      message: "This field is required",
    })
    .test({
      test: (val) => (val?.length as number) <= 3,
      message: "Please enter no more than 3 hashtags",
    }),
});
