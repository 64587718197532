/* eslint-disable @typescript-eslint/no-unused-vars */
import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "..";

const middlewaresClosure = (): Middleware => {
  return (store: MiddlewareAPI<AppDispatch, RootState>) =>
    (next) =>
    async (action: any) => {
      switch (action.type) {
        default:
          next(action);
          break;
      }
    };
};

export const netMiddleware = middlewaresClosure();
