import React from "react";
import "./styles.scss";
import closeIcon from "../../assets/icons/close.svg";
import { Modal, ModalProps } from "antd";

interface Props extends ModalProps {
  visible: boolean;
  onDismis: () => void;
  width?: number;
  className?: string;
  maskClosable?: boolean;
  title?: string;
}

const CustomModalOrange: React.FC<Props> = ({
  visible,
  onDismis,
  width,
  className,
  children,
  maskClosable = true,
  title,
  ...props
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      closable={true}
      maskClosable={maskClosable}
      centered
      width={width}
      closeIcon={false}
      onCancel={onDismis}
      className={`orange-modal ${className}`}
      {...props}
    >
      <div className="confirm-modal__header">
        <h3>{title}</h3>
        <img src={closeIcon} alt="close-icon" onClick={onDismis} />
      </div>
      <div className="modal-content">{children}</div>
    </Modal>
  );
};

export default CustomModalOrange;
