import { baseRequest } from "./base-request";

const statsApi = {
  getStat: (params: { query: string }) => {
    const endpoint = "/api/v1/stat";
    return baseRequest("get", endpoint, {}, params);
  }
};

export default statsApi;
