import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    collapsed: false,
    toastMessage: ''
};

export const LayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleCollapsed: (state) => {
            state.collapsed = !state.collapsed
        },
        toastError: (state, action) => {
            state.toastMessage = action.payload?.message
        },
        clearToastError: (state) => {
            state.toastMessage = ''
        }
    }
})

export const { toggleCollapsed, toastError, clearToastError } = LayoutSlice.actions;

export default LayoutSlice.reducer;
