import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { PATH } from '../../../constants/paths';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const NotFound = () => {
    useDocumentTitle('Not Found', true);
    
    return (
        <div className="not-found-page">
            <div className="not-found-container">
                <h1 className="error-status">404</h1>
                <div className="error-text">
                    <h2>Sorry!</h2>
                    <h3>The page you’re looking for was not found.</h3>
                </div>
            </div>
            <div className="home-redirect">
                <Link to={PATH.GENERAL_STAT}>Back to home</Link>
            </div>
        </div>
    )
}

export default NotFound
