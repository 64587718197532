import { Input, InputProps } from "antd";
import React from "react";
import "./styles.scss";

interface Props extends InputProps{

}

const AppInput = ({ ...props }:Props) => {
  return (
    <Input 
      {...props} 
    />
  );
};

export default AppInput;
