import React from 'react';
import './styles.scss';
import { Modal } from 'antd';

type Props = {
  visible: boolean;
  onDismis: () => void;
  width?: number;
  customClass?: string;
  maskClosable?: boolean;
};

const CustomModal: React.FC<Props> = ({
  visible,
  onDismis,
  width,
  customClass,
  children,
  maskClosable = false
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      closable={true}
      maskClosable={maskClosable}
      centered
      width={width}
      closeIcon={null}
      onCancel={onDismis}
      className={customClass}
    >
      <div className="modal-content">{children}</div>
    </Modal>
  );
};

export default CustomModal;
