import React from "react";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import { PATH } from "../constants/paths";
import { useAuthentication } from "../contexts/AuthProvider";
import MainLayout from "../layouts/MainLayout";
import BadgeManagement from "../pages/BadgeManagement";
import CreateBadge from "../pages/BadgeManagement/CreateBadge";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/Error/404";
import Login from "../pages/Login";
import MarketPlace from "../pages/MarketPlace";
import NftsManagement from "../pages/NftManagement";
import CreateNft from "../pages/NftManagement/CreateNft";
import NftDetails from "../pages/NftManagement/NftDetails";
import UserManagement from "../pages/UserManagement";
import CreateCreator from "../pages/UserManagement/CreateCreator";
import CreatorDetails from "../pages/UserManagement/CreatorDetails";
import UserDetails from "../pages/UserManagement/UserDetails";
import Withdraw from "../pages/Withdraw";

export default function Routes() {
  return (
    <Switch>
      <Route path="*" element={<NotFound />} />
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path={PATH.GENERAL_STAT} element={<Dashboard />} />
        <Route path="users" element={<UserManagement />} />
        <Route path="users/:id" element={<UserDetails />} />
        <Route path="creators/:id" element={<CreatorDetails />} />
        <Route path="creators/create" element={<CreateCreator />} />
        <Route path={PATH.MARKETPLACE} element={<MarketPlace />} />
        <Route path={"nfts"} element={<NftsManagement />} />
        <Route path={"nfts/:id"} element={<NftDetails />} />
        <Route path={"nfts/create"} element={<CreateNft />} />
        <Route path={"badge"} element={<BadgeManagement />} />
        <Route path={"badge/create"} element={<CreateBadge />} />
        <Route path={"withdraw"} element={<Withdraw />} />
      </Route>
    </Switch>
  );
}

const PrivateRoute = () => {
  const { isAuthenticate, isFetching } = useAuthentication();
  if (!isAuthenticate && isFetching) {
    return <div></div>; // replace with loading component
  }
  if (!isAuthenticate) {
    return <Navigate to={PATH.LOGIN} replace />;
  }
  return <MainLayout />;
};
