import { parseUnits } from "@ethersproject/units";
import { BigNumber as BN } from "bignumber.js";
import { BigNumber, ethers } from "ethers";
import { DEFAULT_DECIMALS } from "../constants/token";

export const parseBalance = (value: string, decimals = DEFAULT_DECIMALS) => {
  return parseUnits(value || "0", decimals);
};

export const formatBalance = (
  value: ethers.BigNumberish,
  decimals = DEFAULT_DECIMALS,
  maxFraction = 0
) => {
  const formatted = ethers.utils.formatUnits(
    Number(value)
      ? toFixedDecimal(new BN(value.toString()).toFixed(), 0)
      : BigNumber.from("0"),
    decimals
  );
  if (maxFraction > 0) {
    const split = formatted.split(".");
    if (split.length > 1) {
      return split[0] + "." + split[1].substr(0, maxFraction);
    }
  }
  return formatted;
};

export function toFixedDecimal(
  val: string | number,
  decimals = DEFAULT_DECIMALS
): string {
  val = String(val);
  const split = val.split(".");
  if (split.length > 1) {
    if (decimals) {
      return split[0] + "." + split[1].substr(0, decimals);
    } else {
      return split[0];
    }
  } else {
    return val;
  }
}

export const formatIntBalance = (num: string | number, decimals = 4) => {
  const balanceSplit = String(num).split('.');

  if (balanceSplit.length === 1 || balanceSplit[1] === '0') {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
    });

    return formatter.format(Number(balanceSplit[0]) || 0);
  } else {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
    });
    if (balanceSplit[1].length < decimals) {
      return `${formatter.format(Number(balanceSplit[0]))}.${balanceSplit[1]}`;
    } else {
      return `${formatter.format(
        Number(balanceSplit[0])
      )}.${balanceSplit[1].slice(0, decimals)}`;
    }
  }
};
