import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { formatBalance } from "../../../functions/format";
import musicIcon from "../../../assets/icons/music-icon.svg";
import collectionImage from "../../../assets/icons/collection-icon.svg";
import walletImage from "../../../assets/icons/wallet-account.svg";
import ethereumImage from "../../../assets/icons/ethereum-network.svg";

import { IOrders, Nft } from "../types";
import "./styles.scss";
import { shortenAddress } from "../../../helpers/wallet";
import { BigNumber, ethers } from "ethers";
import nftApi from "../../../api/nft";
import { Col, Row, Tooltip } from "antd";
import BarChart from "../../../components/BarChart";
import { LineChart } from "../../../components/LineChart";
import moment from "moment";
import { getAdminContract } from "../../../functions/contract";
import ActionsModal from "../../../components/ActionsModal";
import PieChart from "../../../components/PieChart";

const initInfo = [
  {
    name: "Creator",
    image: walletImage,
    value: shortenAddress(ethers.constants.AddressZero),
  },
  {
    name: "Collection",
    image: collectionImage,
    value: "",
  },
  {
    name: "Blockchain",
    image: ethereumImage,
    value: "Ethereum",
  },
];

const initGrossData = {
  labels: ["Owned", "For sale", "Hidden"],
  datasets: [
    {
      data: ["35", "35", "30"],
      backgroundColor: ["#FF3E3E", "#FF8200", "#10B981"],
    },
  ],
};

const initSaleData = {
  labels: [
    "Number for sale",
    "Min list price",
    "Max list price",
    ...Array(1).fill(""),
  ],
  datasets: [
    {
      data: ["2290", "1500", "1500"],
      backgroundColor: ["#FF3E3E", "#FF8200", "#FFBD3E"],
    },
  ],
};

const initPriceData = {
  labels: ["Min price", "Max price", "Average price", ...Array(1).fill("")],
  datasets: [
    {
      data: ["1390", "2290", "1500"],
      backgroundColor: ["#FF8200", "#FFBD3E", "#FFBD3E"],
    },
  ],
};

const initReSaleData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Total Re-sales",
      borderColor: "#FF8200",
      backgroundColor: "rgba(255, 130, 0, 0.1)",
      data: [65, 59, 80, 81, 56, 55, 40],
      tension: 0.1,
    },
  ],
};

const NftDetails = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [nftDetails, setNftDetails] = useState<Nft>();
  const [nftInfo, setNftInfo] = useState(initInfo);

  const [grossData] = useState(initGrossData);
  const [saleData] = useState(initSaleData);
  const [priceData] = useState(initPriceData);
  const [resaleData, setReSaleData] = useState(initReSaleData);
  const [orders, setOrders] = useState<IOrders>();

  const [isOpenModalHideNft, setIsOpenModalHideNft] = useState<boolean>(false);
  const [isOpenModalBurnNft, setIsOpenModalBurnNft] = useState<boolean>(false);
  const [needFetch, setNeedFetch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const res = await nftApi.getNftDetails(pathname.split("/")[2]);
        if (res.status === 200) {
          setNftDetails(res.data.data);
          const dataNft = res.data.data;

          const infoData = [
            {
              name: "Creator",
              image: dataNft.creator.avatar,
              value: `${dataNft.creator.firstName} ${dataNft.creator.lastName}`,
            },
            {
              name: "Collection",
              image: dataNft.collection.image,
              value: dataNft.collection.name,
            },
            {
              name: "Blockchain",
              image: ethereumImage,
              value: "Ethereum",
            },
          ];

          setNftInfo(infoData);
        }
      } catch (error) {}
    })();
  }, [pathname, needFetch]);

  const handleHideNft = async () => {
    const res = await nftApi.handleHideNft(pathname.split("/")[2]);
    if (res.status === 200) {
      setNeedFetch(new Date().getTime().toString());
    }
  }

  useEffect(() => {
    (() => {
      const arr = [6, 5, 4, 3, 2, 1, 0];
      const labels = arr.map((item) => {
        const date = new Date().getTime() - item * 24 * 3600 * 1000;
        return moment(date).format("MMM-Do");
      });
      setReSaleData({
        ...initReSaleData,
        labels,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!nftDetails?.orderId) return;
        const adminContract = getAdminContract();
        let orders = await adminContract.orders(nftDetails?.orderId);
        orders = {
          owner: orders.owner,
          price: BigNumber.from(orders.price).toString(),
          quantity: Number(orders.quantity),
          tokenId: Number(orders.tokenId),
        };

        setOrders(orders);
      } catch (error) {}
    })();
  }, [nftDetails?.orderId]);

  return (
    <div className="nft-details-container">
      <div className="nft-details-container__header">
        <FiArrowLeft
          className="btn-back"
          onClick={() => {
            navigate("/nfts", { state });
          }}
        />
        {nftDetails?.status === "hidden" && (
          <div className="status status__error">Hidden</div>
        )}
        {nftDetails?.status === "sale" && (
          <div className="status status__success">On Sale</div>
        )}
      </div>
      <div className="nft-details-container__body">
        <div className="nft-details-container__body-info">
          <div className="left">
            {nftDetails?.fileExtension === "mp4" ? (
              <video
                src={nftDetails?.metaData}
                autoPlay
                controls
                width="100%"
              />
            ) : (
              <div className="image-container">
                <img
                  src={nftDetails?.cover || nftDetails?.metaData}
                  alt=""
                  width="100%"
                  height="100%"
                />
                {nftDetails?.fileExtension === "mp3" && (
                  <div className="gradient-fill">
                    <div
                      style={{
                        backgroundImage: `url(${musicIcon})`,
                      }}
                    />
                    <audio
                      src={nftDetails?.metaData}
                      autoPlay
                      controls
                      className="audio-controller"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="tag-container">
              {nftDetails?.tags.map((item) => {
                return (
                  <Tooltip title={(item as any)?.name}>
                    <div className="tag-item">
                      <span>{`#${(item as any)?.name}`}</span>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
          <div className="right">
            <div className="nft-name">{nftDetails?.name}</div>
            <div className="price-line">
              Price{" "}
              <span className="price">
                {nftDetails?.orderId
                  ? formatBalance(nftDetails?.price || "0")
                  : "-"}{" "}
                USDT
              </span>
              <span className="dot-character">·</span>
              <span>{`${orders?.quantity || "0"} of ${
                nftDetails?.amount
              } available`}</span>
            </div>
            <div className="price-line">
              Created on{" "}
              <span className="color-white">
                {moment(nftDetails?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="desc">{nftDetails?.description}</div>
            <div className="block-info">
              {nftInfo.map((item, index: number) => {
                return (
                  <div className="mt-24" key={index}>
                    <div className="label">{item.name}</div>
                    <div className="value">
                      <img src={item.image} alt="" height={40} width={40} />
                      <Tooltip title={item.value}>
                        <div>{item.value}</div>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="btn-container">
              <button
                className="btn-hide"
                onClick={() => {
                  setIsOpenModalHideNft(true);
                }}
              >
                { nftDetails?.status === "hidden" ? "Unhide NFT" : "Hide NFT" }
              </button>
              <button
                className="btn-burn"
                onClick={() => {
                  setIsOpenModalBurnNft(true);
                }}
              >
                Burn NFT
              </button>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <Row gutter={24} className="dashboard-page__row">
            <Col xs={24} lg={12}>
              <div className="chart-name">Price</div>
              <BarChart data={priceData} />
            </Col>
            <Col xs={24} lg={12}>
              <div className="chart-name">Curent Marketplace statistics</div>
              <BarChart data={saleData} />
            </Col>
          </Row>
          <Row gutter={24} className="dashboard-page__row">
            <Col xs={24} lg={12}>
              <div className="chart-name">Edition size</div>
              <PieChart data={grossData} className="h-252" />
            </Col>
            <Col xs={24} lg={12}>
              <div className="chart-name">Total Re-sales</div>
              <LineChart className="h-252" data={resaleData} />
            </Col>
          </Row>
        </div>
      </div>
      {isOpenModalHideNft && (
        <ActionsModal
          visible={isOpenModalHideNft}
          onDismis={() => setIsOpenModalHideNft(false)}
          title={"HIDE NFT"}
          message={"Are you sure you want to hide this NFT?"}
          onConfirm={() => {
            setIsOpenModalHideNft(false);
            handleHideNft();
          }}
        />
      )}
      {isOpenModalBurnNft && (
        <ActionsModal
          visible={isOpenModalBurnNft}
          onDismis={() => setIsOpenModalBurnNft(false)}
          title={"BURN NFT"}
          message={
            "Are you sure you want to burn this NFT? This NFT will be permanently removed from the database."
          }
          onConfirm={() => {
            setIsOpenModalBurnNft(false);
          }}
        />
      )}
    </div>
  );
};

export default NftDetails;
