export enum StatusEnum {
    PAUSED = 'paused',
    ACTIVE = 'active',
    VERIFIED = 'verified',
    NOT_VERIFIED = 'not_verified',
    SALE = 'sale',
    HIDDEN = 'hidden',
}

export const StatusLabels = {
    [StatusEnum.PAUSED]: 'Paused',
    [StatusEnum.ACTIVE]: 'Active',
    [StatusEnum.VERIFIED]: 'Verified',
    [StatusEnum.NOT_VERIFIED]: 'Not Verified',
    [StatusEnum.SALE]: 'On sale',
    [StatusEnum.HIDDEN]: 'Hidden',
};
