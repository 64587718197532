import React from "react";
import { Line } from "react-chartjs-2";
import "./styles.scss";

export const LineChart = ({ className, data }: { className: string, data: any }) => {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        grid: {
          display: false,
        },
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      }
    }
  };

  return (
    <div className={`line-chart-container ${className}`}>
      <Line data={data} options={options} />
    </div>
  );
};
