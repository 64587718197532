import React, { useState } from "react";
import "./styles.scss";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { isArrayZero, scaleHeightChart } from "../../functions/utils";
import BigNumber from "bignumber.js";

export default function BarChart(props: any) {
  const { data } = props;
  const [maxHeight, setMaxHeight] = useState(0);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "#ACACAC",
            fontFamily: "Montserrat",
            fontSize: 12,
            [maxHeight && "max"]: maxHeight,
          },
          gridLines: {
            color: "#212127",
          },
          afterUpdate: (e: any) => {
            const sort = [...data?.datasets[0].data].sort((a, b) =>
              new BigNumber(b).minus(a).toNumber()
            );

            const height =
              !isArrayZero(data?.datasets[0].data) && e.ticksAsNumbers
                ? scaleHeightChart(sort[0], e.ticksAsNumbers[0])
                : 0;
            setMaxHeight(height);
          },
        },
      ],
      xAxes: [
        {
          // barPercentage: 0.3,
          barThickness: "40",
          ticks: {
            fontColor: "#ACACAC",
            fontFamily: "Montserrat",
            fontSize: 12,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: true,
        color: "#fff",
        align: "end",
        anchor: "end",
        font: { size: 12, family: "Montserrat" },
      },
      beforeInit: (chart: any, args: any, options: any) => {
        console.log(chart, args, options);
      },
    },
    legend: {
      display: false,
    },
    events: [],
  };

  return (
    <div className="bar-chart-container">
      {data.datasets ? (
        <Bar data={data} options={options} />
      ) : (
        <div className="no-data">No Data</div>
      )}
    </div>
  );
}
