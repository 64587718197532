import { Column } from "../../components/CustomTable/types";
import { FileTypeEnum } from "../../constants/file-type";
import { StatusEnum, StatusLabels } from "../../constants/Status";
import { formatBalance, formatIntBalance } from "../../functions/format";
import { Creator } from "../UserManagement/types";

export const MAXIMUM_IMAGE_COVER_SIZE = 5;
export const ACCEPTED_IMAGE_COVER_TYPES = [
  FileTypeEnum.PNG,
  FileTypeEnum.JPG,
  FileTypeEnum.JPEG,
  FileTypeEnum.GIF,
  FileTypeEnum.WEBP,
];
export const NFT_CATEGORIES = [
  { value: "Art", label: "Art" },
  { value: "Blondie", label: "Blondie" },
  { value: "MILF", label: "MILF" },
  { value: "Celebrities", label: "Celebrities" },
  { value: "Video", label: "Video" },
  { value: "Bikini", label: "Bikini" },
  { value: "Amateur", label: "Amateur" },
  { value: "Others", label: "Others" },
];
export const COLUMNS: Column[] = [
  {
    label: "ID",
    field: "id",
    type: "text",
    width: "10%",
  },
  {
    label: "NFT name",
    field: "name",
    type: "text",
    width: "20%",
  },
  {
    label: "Creator",
    field: "creator",
    type: "text",
    width: "20%",
  },
  {
    label: "Date created",
    field: "createdAt",
    type: "date",
    width: "15%",
  },
  {
    label: "Status",
    field: "status",
    type: "status",
    customClass: "text-only",
    filter: {
      type: "select",
      options: [
        {
          label: StatusLabels[StatusEnum.SALE],
          value: StatusEnum.SALE,
        },
        {
          label: StatusLabels[StatusEnum.HIDDEN],
          value: StatusEnum.HIDDEN,
        },
      ],
    },
    width: "15%",
  },
  {
    label: "Total sales (USDT)",
    field: "totalSales",
    type: "number",
    width: "20%",
    customDisplay: (data: Creator) => {
      return formatIntBalance(formatBalance((data as any).totalSales), 2);
    },
  },
];
