import { FileTypeEnum, FileTypeLabels } from '../constants/file-type';

export const validFileSize = (file: File, maximumFileSize: number) => {
    return file.size <= maximumFileSize * 1024 * 1024;
};

export const validFileType = (file: File, acceptedFileType: string[]) => {
    return acceptedFileType.includes(file.type);
};

export const getAcceptedFileTypeStr = (acceptedFileType: FileTypeEnum[]) => {
    const acceptedFileTypeLabelsReversed = acceptedFileType
        .map((item) => FileTypeLabels[item].toUpperCase())
        .reverse();
    const acceptedFileTypeLabelReversedStr = acceptedFileTypeLabelsReversed.join(', ').replace(', ', ' or ');
    const acceptedFileTypeLabels = acceptedFileTypeLabelReversedStr.split(', ').reverse();
    return acceptedFileTypeLabels.join(', ');
};
