/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, clearError } from './slice';
import { PATH } from '../../constants/paths';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from './schema';
import { RootState } from '../../store';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import logoImg from '../../assets/logo.png';
import errorIcon from '../../assets/icons/error.svg';

export default function Login() {
    useDocumentTitle('Login', true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authSelector = useSelector((state: RootState) => state.auth);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    });

    const onLoginSubmit = (data: any) => {
        try {
            dispatch(login({...data, onSuccess: () => navigate(PATH.GENERAL_STAT)}));
        }
        catch (err: any) {
            toast.error(err?.message);
        }
    }

    useEffect(() => {
        if (authSelector?.currentUser && localStorage.getItem('access_token')) {
            navigate(PATH.GENERAL_STAT);
        }
    }, [])

    useEffect(() => {
        if (authSelector?.errorMessage) {
            setTimeout(() => {
                dispatch(clearError());
            }, 3000)
        }
    }, [authSelector?.errorMessage])

    return (
        <div className="authentication-layout">
            <div className={authSelector?.errorMessage ? 'top-toast-error toast-visible' : 'top-toast-error'}>
                {authSelector?.errorMessage ? (
                    <>
                        <img src={errorIcon} alt="" /> {authSelector?.errorMessage}
                    </>
                ) : ''}
            </div>
            <div className="authentication-logo">
                <img src={logoImg} alt="" />
            </div>
            <div className="authentication-box">
                <div className="box-logo-mobile">
                    <img src={logoImg} alt="" />
                </div>
                <div className="box-title">Login</div>
                <form onSubmit={handleSubmit(onLoginSubmit)} className="form-container">
                    <div className="form-item">
                        <div className="form-item__label">Email</div>
                        <div className={errors?.email?.message ? 'form-item__input input-error' : 'form-item__input'}>
                            <input {...register('email')} type="text" />
                        </div>
                        <div className="form-item__error">{errors?.email?.message}</div>
                    </div>
                    <div className="form-item">
                        <div className="form-item__label">Password</div>
                        <div className={errors?.password?.message ? 'form-item__input input-error' : 'form-item__input'}>
                            <input {...register('password')} type="password" maxLength={16} />
                        </div>
                        <div className="form-item__error">{errors?.password?.message}</div>
                    </div>
                    <button className="btn-submit" type="submit" disabled={authSelector?.isLoading}>
                        {authSelector?.isLoading ? 'Signing In ...' : 'Sign In'}
                    </button>
                </form>
            </div>
        </div>
    )
}
