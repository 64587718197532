/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Tooltip } from "antd";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import creatorsApi from "../../../api/creators";
import copyIcon from "../../../assets/icons/copy-icon.svg";
import ActionButton from "../../../components/ActionButton";
import ActionsModal from "../../../components/ActionsModal";
import BarChart from "../../../components/BarChart";
import CustomTable from "../../../components/CustomTable";
import Status from "../../../components/Status";
import { StatusEnum } from "../../../constants/Status";
import { getAdminContract } from "../../../functions/contract";
import { formatBalance, formatIntBalance } from "../../../functions/format";
import { copyToClipboard, isArrayZero } from "../../../functions/utils";
import { shortenAddress } from "../../../helpers/wallet";
import DetailsHeader from "../DetailsHeader";
import { Creator } from "../types";
import "./styles.scss";

const initDataFan = {
  labels: ["No. of Fans", "No. of Favorites", ...Array(2).fill("")],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#FF3E3E", "#FFBD3E"],
    },
  ],
};

const initDataSales = {
  labels: ["Total sales", "Top sales", "Recent sales", ...Array(1).fill("")],
  datasets: [
    {
      data: ["0", "0", "0"],
      backgroundColor: ["#FF3E3E", "#FF8200", "#FFBD3E"],
    },
  ],
};

const initDataPrice = [
  {
    totalNftSolds: "0",
    totalSales: "0",
    avgSalesPrice: "0",
  },
];

const initialAlert = {
  isOpen: false,
  title: "",
  message: "",
  backToListFlag: false,
};

const CreatorDetails: React.FC = () => {
  const [creator, setCreator] = useState<Creator | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenActiveConfirmModal, setIsOpenActiveConfirmModal] =
    useState(false);
  const [isOpenPauseConfirmModal, setIsOpenPauseConfirmModal] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);
  const [alert, setAlert] = useState(initialAlert);

  const [dataFan, setDataFan] = useState<any>();
  const [dataSale, setDataSale] = useState<any>();
  const [dataPrice, setDataPrice] = useState([...initDataPrice]);

  const fetchCreatorById = async (id: number) => {
    try {
      const {
        data: { data },
      } = await creatorsApi.getCreatorById(id);
      setCreator(data);
      const fans = [
        data?.fanChart?.twitterFans,
        data?.fanChart?.favorites,
      ] as number[];

      if (isArrayZero(fans)) {
        setDataSale({});
      } else {
        setDataFan({
          ...initDataFan,
          datasets: [
            {
              ...initDataFan.datasets[0],
              data: fans,
            },
          ],
        });
      }

      const sales = [
        formatBalance(data?.saleChart?.totalSales),
        formatBalance(data?.saleChart?.topSales),
        formatBalance(data?.saleChart?.recentSales),
      ];

      if (isArrayZero(sales)) {
        setDataSale({});
      } else {
        setDataSale({
          ...initDataSales,
          datasets: [
            {
              ...initDataSales.datasets[0],
              data: sales,
            },
          ],
        });
      }

      const price = {
        totalNftSolds: data?.saleStat?.totalNftSold,
        totalSales: formatBalance(data?.saleStat?.totalSales),
        avgSalesPrice: formatBalance(data?.saleStat?.avgSalesPrice),
      };

      setDataPrice([price]);
    } catch (error) {
      console.error(error);
      setCreator(null);
    }
  };

  const handleActiveCreator = async () => {
    try {
      const {
        data: { data },
      } = await creatorsApi.activeCreatorById(Number(id));
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have activated this account. A notification email has been sent to the user",
          backToListFlag: false,
        });
        setCreator((prevCreator) => {
          return (
            prevCreator && {
              ...prevCreator,
              status: StatusEnum.ACTIVE,
            }
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePauseCreator = async () => {
    try {
      const {
        data: { data },
      } = await creatorsApi.pauseCreatorById(Number(id));
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have paused this account. A notification email has been sent to the user",
          backToListFlag: false,
        });
        setCreator((prevCreator) => {
          return (
            prevCreator && {
              ...prevCreator,
              status: StatusEnum.PAUSED,
            }
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCreator = async () => {
    try {
      setIsDeleting(true);
      const {
        data: { data },
      } = await creatorsApi.deleteCreatorById(Number(id));
      setIsDeleting(false);
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have deleted this account. A notification email has been sent to the user",
          backToListFlag: true,
        });
      }
    } catch (error) {
      setIsDeleting(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCreatorById(Number(id));
  }, [id]);

  const handleDismisAlert = () => {
    if (alert.backToListFlag) {
      navigate("/users", { state: true });
    } else {
      setAlert(initialAlert);
    }
  };

  const shortenText = (text?: string) => {
    if (!text) {
      return "";
    }

    if (text.length <= 30) {
      return text;
    }

    return (
      <Tooltip placement="top" title={text}>
        {`${text.substring(0, 30)}...`}
      </Tooltip>
    );
  };

  useEffect(() => {
    (async () => {
      if (!creator?.creatorWallet?.address) return;
      const adminContract = getAdminContract();
      const commission = await adminContract.royalty(
        creator?.creatorWallet?.address
      );

      setCreator({
        ...creator,
        commission: Number(commission),
      });
    })();
  }, [creator?.creatorWallet?.address]);

  return (
    <div className="creator-details">
      <DetailsHeader
        username={creator?.username}
        email={creator?.email}
        status={creator?.status}
        onBack={() => navigate("/users", { state: true })}
        onActive={() =>
          creator?.status === StatusEnum.PAUSED &&
          setIsOpenActiveConfirmModal(true)
        }
        onPause={() =>
          creator?.status === StatusEnum.ACTIVE &&
          setIsOpenPauseConfirmModal(true)
        }
        avatar={creator?.avatar}
      />

      <Row gutter={24}>
        <Col span={16}>
          <div className="user-details__tbl-infos-wrapper">
            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{creator?.id}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td>{shortenText(creator?.username)}</td>
                </tr>
                <tr>
                  <td>Full name</td>
                  <td>
                    {!creator?.firstName && !creator?.lastName
                      ? "N/A"
                      : shortenText(
                          `${creator?.firstName || ""} ${
                            creator?.lastName || ""
                          }`
                        )}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{shortenText(creator?.email)}</td>
                </tr>
                <tr>
                  <td>Twitter account</td>
                  <td>
                    {shortenText(
                      creator?.socials?.find(
                        (social) => social.name === "twitter"
                      )?.link
                    ) || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Wallet address</td>
                  <td>
                    <Tooltip
                      placement="top"
                      title={
                        creator?.creatorWallet?.address ||
                        ethers.constants.AddressZero
                      }
                    >
                      {shortenAddress(
                        creator?.creatorWallet?.address ||
                          ethers.constants.AddressZero
                      )}
                    </Tooltip>
                    <img
                      src={copyIcon}
                      alt=""
                      className="copy-icon"
                      onClick={() =>
                        copyToClipboard(
                          creator?.creatorWallet?.address ||
                            ethers.constants.AddressZero
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Commission</td>
                  <td>{`${creator?.commission || "0"}%`}</td>
                </tr>
                <tr>
                  <td>KYC status</td>
                  <td>
                    <Status
                      status={creator?.kycStatus || StatusEnum.NOT_VERIFIED}
                      customClass="kyc-status"
                    />
                  </td>
                </tr>
                <tr>
                  <td>User status</td>
                  <td>
                    <Status status={creator?.status || StatusEnum.ACTIVE} />
                  </td>
                </tr>
                <tr>
                  <td>NFTs currently on sale</td>
                  <td>
                    {formatIntBalance(creator?.saleStat?.totalNftOnSales || "0")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <CustomTable
            columns={[
              {
                field: "totalNftSolds",
                label: "Total NFTs sold",
                type: "number",
              },
              {
                field: "totalSales",
                label: "Total sales",
                type: "number",
              },
              {
                field: "avgSalesPrice",
                label: "Avg sales price",
                suffix: "USDT",
                type: "number",
              },
            ]}
            data={dataPrice}
          />
        </Col>

        <Col span={8}>
          <div className="creator-details__chart">
            <BarChart data={{ ...dataFan }} />
          </div>

          <div className="creator-details__chart">
            <BarChart data={{ ...dataSale }} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <ActionButton
            className="btn-delete"
            actionName="Delete Account"
            callback={() => setIsOpenDeleteConfirmModal(true)}
            isLoading={isDeleting}
          />
        </Col>
      </Row>

      <ActionsModal
        visible={isOpenActiveConfirmModal}
        onDismis={() => setIsOpenActiveConfirmModal(false)}
        title={"ENABLE THIS ACCOUNT"}
        message={"Are you sure you want to enable this account?"}
        onConfirm={() => {
          setIsOpenActiveConfirmModal(false);
          handleActiveCreator();
        }}
      />

      <ActionsModal
        visible={isOpenPauseConfirmModal}
        onDismis={() => setIsOpenPauseConfirmModal(false)}
        title={"PAUSE THIS ACCOUNT"}
        message={"Are you sure you want to pause this account?"}
        onConfirm={() => {
          setIsOpenPauseConfirmModal(false);
          handlePauseCreator();
        }}
      />

      <ActionsModal
        visible={isOpenDeleteConfirmModal}
        onDismis={() => setIsOpenDeleteConfirmModal(false)}
        title={"DELETE THIS ACCOUNT"}
        message={"Are you sure you want to permanently delete this account?"}
        onConfirm={() => {
          setIsOpenDeleteConfirmModal(false);
          handleDeleteCreator();
        }}
      />

      <ActionsModal
        isOnlyAction={true}
        visible={alert.isOpen}
        onDismis={handleDismisAlert}
        title={alert.title}
        message={alert.message}
        onConfirm={handleDismisAlert}
      />
    </div>
  );
};

export default CreatorDetails;
