import { Column } from "../../components/CustomTable/types";
import { StatusEnum, StatusLabels } from "../../constants/Status";
import { formatBalance, formatIntBalance } from "../../functions/format";
import { Creator } from "./types";

export const userTabs: string[] = ["users", "creators"];

export const statusTabs: string[] = [StatusEnum.ACTIVE, StatusEnum.PAUSED];

export const userColumns: Column[] = [
  {
    field: "id",
    label: "ID",
    type: "text",
    width: "10%",
  },
  {
    field: "username",
    label: "Username",
    type: "text",
    width: "30%",
  },
  {
    field: "email",
    label: "Email",
    type: "text",
    width: "30%",
  },
  {
    field: "kycStatus",
    label: "KYC status",
    type: "status",
    customClass: "text-only",
    filter: {
      type: "select",
      options: [
        {
          value: StatusEnum.NOT_VERIFIED,
          label: StatusLabels[StatusEnum.NOT_VERIFIED],
        },
        {
          value: StatusEnum.VERIFIED,
          label: StatusLabels[StatusEnum.VERIFIED],
        },
      ],
    },
    width: "15%",
  },
  {
    field: "status",
    label: "User status",
    type: "status",
    filter: {
      type: "select",
      options: [
        {
          value: StatusEnum.ACTIVE,
          label: StatusLabels[StatusEnum.ACTIVE],
        },
        {
          value: StatusEnum.PAUSED,
          label: StatusLabels[StatusEnum.PAUSED],
        },
      ],
    },
    width: "15%",
  },
];

export const creatorColumns: Column[] = [
  {
    field: "id",
    label: "ID",
    type: "text",
    width: "10%",
  },
  {
    field: "username",
    label: "Username",
    type: "text",
    width: "15%",
  },
  {
    field: "fullname",
    label: "Full name",
    type: "text",
    customDisplay: (data: Creator) => {
      const fullname = `${data.firstName || ""} ${data.lastName || ""}`;
      return fullname;
    },
    width: "20%",
  },
  {
    field: "totalNfts",
    label: "Total NFTs",
    type: "number",
    width: "10%",
  },
  {
    field: "totalSales",
    label: "Total sales (USDT)",
    type: "number",
    customDisplay: (data: Creator) => {
      return formatIntBalance(formatBalance((data as any).totalSales), 2);
    },
    width: "15%",
  },
  {
    field: "kycStatus",
    label: "KYC status",
    type: "status",
    customClass: "text-only",
    filter: {
      type: "select",
      options: [
        {
          value: StatusEnum.NOT_VERIFIED,
          label: StatusLabels[StatusEnum.NOT_VERIFIED],
        },
        {
          value: StatusEnum.VERIFIED,
          label: StatusLabels[StatusEnum.VERIFIED],
        },
      ],
    },
    width: "15%",
  },
  {
    field: "status",
    label: "Status",
    type: "status",
    filter: {
      type: "select",
      options: [
        {
          value: StatusEnum.ACTIVE,
          label: StatusLabels[StatusEnum.ACTIVE],
        },
        {
          value: StatusEnum.PAUSED,
          label: StatusLabels[StatusEnum.PAUSED],
        },
      ],
    },
    width: "15%",
  },
];
