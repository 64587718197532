import axios from 'axios';
import moment from 'moment';

const endpoint = process.env.REACT_APP_API_SERVER_ENDPOINT;

export const baseRequest = async (method: any, url: any, data?: any, params?: any) => {

    // if(method.toLowerCase() === 'get') {
    //     let newUrl: any = new URL(endpoint + url);
    //     newUrl.search = new URLSearchParams(data);
    //     url = newUrl;
    // }

    const lastActive = localStorage.getItem('last_active');
    if (!lastActive) {
        localStorage.setItem('last_active', moment().format());
    }
    else {
        if (moment().subtract(1, 'h') > moment(lastActive) && localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('last_active');
            window.location.reload();
        }
        else localStorage.setItem('last_active', moment().format());
    }

    const api = await axios.request({
        baseURL: endpoint,
        method,
        url,
        data,
        params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('access_token')}`
        }
    });
    return api;

};
