import React from 'react';
import './styles.scss';
import { Pagination } from 'antd';

type Props = {
    total: number;
    current: number;
    onChange: (page: number) => void;
};

const CustomPagination: React.FC<Props> = ({ current, total, onChange }) => {
    return (
        <Pagination
            defaultCurrent={current}
            current={current}
            showSizeChanger={false}
            total={total}
            onChange={onChange}
        />
    );
};

export default CustomPagination;
