import React from 'react';
import { StatusEnum } from '../../../constants/Status';
import './styles.scss';

type Props = {
    currentTab: string;
    onCurrentTabChange: (tab: string) => void;
    tabs: string[];
};

const Tabs: React.FC<Props> = ({ currentTab, onCurrentTabChange, tabs }) => {
    return (
        <div className="tabs">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`tabs__item ${currentTab === tab && 'active'}`}
                    onClick={() => onCurrentTabChange(tab)}
                >
                    {tab === StatusEnum.ACTIVE
                        ? 'activate'
                        : tab === StatusEnum.PAUSED
                        ? 'pause'
                        : tab}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
