import * as yup from "yup";

export const badgeSchema = yup.object().shape({
  price: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) > 0,
      message: "Price must be greater than 0",
      name: "min",
    }),
  name: yup.string().required("This field is required"),
  amount: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) > 0,
      message: "The number of copies must be greater than 0",
      name: "min",
    }),
  duration: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) < 365,
      message: "The duration must not be greater than 365 days",
      name: "max",
    })
    .test({
      test: (val) => Number(val) > 0,
      message: "The duration must be greater than 0",
      name: "min",
    }),
  discountRate: yup
    .string()
    .required("This field is required")
    .test({
      test: (val) => Number(val) <= 50,
      message: "The duration must be less than or equal to 50",
      name: "max",
    }),
});
