import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

type Props = {
    children: React.ReactNode
}

const AuthContext = React.createContext({
    isAuthenticate: false,
    isFetching: true
})

export default function AuthProvider({ children }: Props) {
    const location = useLocation();
    const [isAuthenticate, setIsAuthenticate] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            setIsAuthenticate(false);
            setIsFetching(false);
        }
        else if (localStorage.getItem('access_token') &&
            moment().subtract(1, 'h') < moment(localStorage.getItem('last_active'))) {
                setIsAuthenticate(true);
                setIsFetching(false);
            }
        else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('last_active');
            setIsAuthenticate(false);
            setIsFetching(false);
        }
    }, [location])

    return (
        <AuthContext.Provider value={{ isAuthenticate, isFetching }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuthentication() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthentication must be used within a AuthProvider');
    }
    return context;
}
