import React from "react";
import NumberFormat from "react-number-format";
import { DEFAULT_DECIMALS } from "../../../constants/token";
import { NumberFormatProps } from "./AppInputAmount";

// interface AppInputAmount extends Omit<NumberFormatProps, 'onChange'> {
interface AppInputAmountProps extends NumberFormatProps {}

export function AppInputAmount({
  decimalScale = DEFAULT_DECIMALS, // Default USDT
  ...props
}: AppInputAmountProps) {
  return (
    <NumberFormat
      // customInput={AppInput}
      thousandsGroupStyle="thousand"
      decimalSeparator="."
      displayType="input"
      type="text"
      allowNegative={false}
      decimalScale={decimalScale}
      {...props}
    />
  );
}
