/* eslint-disable no-extend-native */
import { BigNumber as BN } from "bignumber.js";
// Bootstrap...
const _additionPrototypeArray = () => {
  Array.prototype.equals = function (array: Array<any>) {
    // if the other array is a falsy value, return
    if (!array) return false;

    // compare lengths - can save a lot of time
    if (this.length !== array.length) return false;

    for (let i = 0, l = this.length; i < l; i++) {
      // Check if we have nested arrays
      if (this[i] instanceof Array && array[i] instanceof Array) {
        // recurse into the nested arrays
        if (!this[i].equals(array[i])) return false;
      } else if (this[i] !== array[i]) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        return false;
      }
    }
    return true;
  };

  Array.prototype.sortBy = function (
    direction = "desc",
    callback: (a: any, b: any) => any
  ): any[] {
    return this.sort((a, b) => {
      const [valueA, valueB] = callback(a, b);

      if (direction === "asc") {
        if (new BN(valueA).isNaN()) {
          return valueA.localeCompare(valueB);
        } else {
          return new BN(valueA).minus(valueB);
        }
      } else {
        if (new BN(valueA).isNaN()) {
          return valueB.localeCompare(valueA);
        } else {
          return new BN(valueB).minus(valueA);
        }
      }
    });
  };
};

export const additionPrototype = _additionPrototypeArray;
