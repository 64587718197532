import React, { useEffect, useMemo, useRef, useState } from 'react';
import './styles.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import { Column } from '../../components/CustomTable/types';
import SearchBar from '../../components/SearchBar';
import { creatorColumns, userColumns, userTabs } from './constants';
import Tabs from './Tabs';
import CustomPagination from '../../components/CustomPagination';
import useTableFetch from '../../hooks/useTableFetch';
import AddIcon from '../../assets/icons/add-icon.svg';
import Status from '../../components/Status';
import { StatusEnum } from '../../constants/Status';
import usersApi from '../../api/users';
import ActionsModal from '../../components/ActionsModal';
import { Creator, User } from './types';
import creatorsApi from '../../api/creators';

const initialAlert = {
    isOpen: false,
    title: '',
    message: '',
    backToListFlag: false,
};

const DEFAULT_TAB = 'users';
const OPTIONS_KEY = 'users-mng';

const UserManagement: React.FC = () => {
    const location = useLocation();
    const holdPrevStateFlag = useRef<boolean>(location.state as boolean);
    const navigate = useNavigate();
    const selectedUser = useRef<User>();
    const selectedCreator = useRef<Creator>();
    const [isOpenActiveConfirmModal, setIsOpenActiveConfirmModal] = useState(false);
    const [isOpenPauseConfirmModal, setIsOpenPauseConfirmModal] = useState(false);
    const [alert, setAlert] = useState(initialAlert);
    const [reset, setReset] = useState(0);
    const pageOptions = useMemo(() => {
        if (!holdPrevStateFlag.current || reset) {
            return;
        }
        
        const optionsStr = localStorage.getItem(OPTIONS_KEY);
        if (optionsStr) {
            const option = JSON.parse(optionsStr);
            localStorage.removeItem(OPTIONS_KEY);
            return option;
        }
        
        return;
    }, [reset]);
    const [currentTab, setCurrentTab] = useState(() => holdPrevStateFlag && pageOptions ? pageOptions.tab : DEFAULT_TAB);
    const [columns, setColumns] = useState<Column[]>(() => holdPrevStateFlag.current && pageOptions && pageOptions.tab !== DEFAULT_TAB ? creatorColumns : userColumns);

    useEffect(() => {
        userColumns[4].customDisplay = (data) => {
            return (
                <Status
                    customClass="editable"
                    status={data.status}
                    editable={true}
                    statusList={[StatusEnum.ACTIVE, StatusEnum.PAUSED]}
                    onChange={(status: StatusEnum) => {
                        selectedCreator.current = undefined;
                        selectedUser.current = data;
                        handleStatusChange(status, data.id);
                    }}
                />
            );
        };

        creatorColumns[6].customDisplay = (data) => {
            return (
                <Status
                    customClass="editable"
                    status={data.status}
                    editable={true}
                    statusList={[StatusEnum.ACTIVE, StatusEnum.PAUSED]}
                    onChange={(status: StatusEnum) => {
                        selectedUser.current = undefined;
                        selectedCreator.current = data;
                        handleStatusChange(status, data.id);
                    }}
                />
            );
        };
    }, [])

    const handleActiveUser = async () => {
        if (!selectedUser.current) {
            return;
        }

        try {
            const {
                data: { data },
            } = await usersApi.activeUserById(selectedUser.current.id);
            if (data) {
                selectedUser.current.status = StatusEnum.ACTIVE;
                setAlert({
                    isOpen: true,
                    title: 'SUCCESS!',
                    message:
                        'You have activated this account. A notification email has been sent to the user',
                    backToListFlag: false,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePauseUser = async () => {
        if (!selectedUser.current) {
            return;
        }

        try {
            const {
                data: { data },
            } = await usersApi.pauseUserById(selectedUser.current.id);
            if (data) {
                selectedUser.current.status = StatusEnum.PAUSED;
                setAlert({
                    isOpen: true,
                    title: 'SUCCESS!',
                    message:
                        'You have paused this account. A notification email has been sent to the user',
                    backToListFlag: false,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleActiveCreator = async () => {
        if (!selectedCreator.current) {
            return;
        }

        try {
            const {
                data: { data },
            } = await creatorsApi.activeCreatorById(selectedCreator.current.id);
            if (data) {
                selectedCreator.current.status = StatusEnum.ACTIVE;
                setAlert({
                    isOpen: true,
                    title: 'SUCCESS!',
                    message:
                        'You have activated this account. A notification email has been sent to the user',
                    backToListFlag: false,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePauseCreator = async () => {
        if (!selectedCreator.current) {
            return;
        }

        try {
            const {
                data: { data },
            } = await creatorsApi.pauseCreatorById(selectedCreator.current.id);
            if (data) {
                selectedCreator.current.status = StatusEnum.PAUSED;
                setAlert({
                    isOpen: true,
                    title: 'SUCCESS!',
                    message:
                        'You have paused this account. A notification email has been sent to the user',
                    backToListFlag: false,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleStatusChange = (status: StatusEnum, id: number) => {
        if (status === StatusEnum.ACTIVE) {
            setIsOpenActiveConfirmModal(true);
        }

        if (status === StatusEnum.PAUSED) {
            setIsOpenPauseConfirmModal(true);
        }
    };

    const handleDismisAlert = () => {
        setAlert(initialAlert);
    };

    const handleCurrentTabChange = (tab: string) => {
        setCurrentTab(tab);
        setReset((prev) => prev + 1);
        setQuery('');

        if (tab === 'users') {
            setColumns(userColumns);
            return;
        }

        if (tab === 'creators') {
            setColumns(creatorColumns);
            return;
        }
    };

    const {
        state: { page, rows, totalRow },
        query,
        setState,
        setQuery,
        isLoading,
        params,
        setParams,
    } = useTableFetch(`/api/v1/admin/${currentTab || DEFAULT_TAB}`, pageOptions);

    return (
        <div className="user-management">
            <div className="user-management__rows">
                <Tabs
                    currentTab={currentTab}
                    onCurrentTabChange={handleCurrentTabChange}
                    tabs={userTabs}
                />

                <div className="right-content">
                    {currentTab === 'creators' && (
                        <div className="btn-create-wrapper">
                            <button className="btn-create" onClick={() => {
                                const pageOptions = { tab: currentTab };
                                localStorage.setItem(OPTIONS_KEY, JSON.stringify(pageOptions));
                                navigate('/creators/create');
                            }}>
                                <img src={AddIcon} alt="" />
                                Create
                            </button>
                        </div>
                    )}
                    <SearchBar reset={reset} onChange={(value) => setQuery(value)} initState={holdPrevStateFlag && pageOptions?.query && String(pageOptions?.query)} />
                </div>
            </div>

            <CustomTable
                data={rows}
                columns={columns}
                isLoading={isLoading}
                clickable={true}
                path={`/${currentTab}`}
                filterValue={params}
                query={query}
                onFilter={(filterParams) =>
                    setParams((prevParams: any) => ({
                        ...prevParams,
                        ...filterParams,
                    }))
                }
                onRowClick={(id: number) => {
                    const pageOptions = { tab: currentTab, page, query, params };
                    localStorage.setItem(OPTIONS_KEY, JSON.stringify(pageOptions));
                    navigate(`/${currentTab}/${id}`);
                }}
            />

            {rows.length ? (
                <div className="user-management__pagination">
                    <CustomPagination
                        total={totalRow}
                        current={page}
                        onChange={(page) =>
                            setState((prevState) => ({ ...prevState, page }))
                        }
                    />
                </div>
            ) : null}

            <ActionsModal
                visible={isOpenActiveConfirmModal}
                onDismis={() => setIsOpenActiveConfirmModal(false)}
                title={'ENABLE THIS ACCOUNT'}
                message={'Are you sure you want to enable this account?'}
                onConfirm={() => {
                    setIsOpenActiveConfirmModal(false);
                    if (selectedCreator.current) {
                        handleActiveCreator()
                    } else {
                        handleActiveUser();
                    }
                }}
            />

            <ActionsModal
                visible={isOpenPauseConfirmModal}
                onDismis={() => setIsOpenPauseConfirmModal(false)}
                title={'PAUSE THIS ACCOUNT'}
                message={'Are you sure you want to pause this account?'}
                onConfirm={() => {
                    setIsOpenPauseConfirmModal(false);
                    if (selectedCreator.current) {
                        handlePauseCreator()
                    } else {
                        handlePauseUser();
                    }
                }}
            />

            <ActionsModal
                isOnlyAction={true}
                visible={alert.isOpen}
                onDismis={handleDismisAlert}
                title={alert.title}
                message={alert.message}
                onConfirm={handleDismisAlert}
            />
        </div>
    );
};

export default UserManagement;
