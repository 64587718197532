import React from 'react';
import './styles.scss';

export default function StatCard(props: any) {
    const { type = 'default', icon, data, text } = props;

    const formatCommaNumber = (value: number) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={`stat-card card-${type}`}>
            <div className="stat-card__left">
                <div className="card-data">{formatCommaNumber(data)}</div>
                <div className="card-text">{text}</div>
            </div>
            <div className="stat-card__right">
                <div className="icon-container">
                    <img src={icon} alt="" />
                </div>
            </div>
        </div>
    )
}
