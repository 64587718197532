import React, { useEffect } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useTableFetch from "../../hooks/useTableFetch";
import CustomPagination from "../../components/CustomPagination";
import { COLUMNS } from "./constants";
import SearchBar from "../../components/SearchBar";
import AddIcon from "../../assets/icons/add-icon.svg";
import CustomTable from "../../components/CustomTable";

const NftsManagement: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    state: { page, rows, totalRow },
    params,
    query,
    setState,
    setQuery,
    isLoading,
    setParams,
  } = useTableFetch("/api/v1/nft", {
    page: (state as { page: number })?.page,
    query: (state as { query: string })?.query,
    params: (state as { params: string })?.params,
  });

  useEffect(() => {
    return window.history.replaceState({}, document.title);
  }, []);

  return (
    <div className="nft-management">
      <div className="nft-management__rows">
        <div className="right-content">
          <div className="btn-create-wrapper">
            <button
              className="btn-create"
              onClick={() => {
                navigate("/nfts/create");
              }}
            >
              <img src={AddIcon} alt="add-icon" />
              Create
            </button>
          </div>
          <SearchBar
            onChange={(value) => setQuery(value)}
            initState={(state as { query?: string })?.query || ""}
          />
        </div>
      </div>

      <CustomTable
        data={rows}
        columns={COLUMNS}
        isLoading={isLoading}
        query={query}
        path="nfts"
        clickable
        filterValue={params}
        onFilter={(filterParams) => {
          setParams((prevParams: any) => {
            return {
              ...prevParams,
              ...filterParams,
            };
          });
        }}
        onRowClick={(id: number) => {
          navigate(`${id}`, { state: { page, query, params } });
        }}
      />

      {rows.length ? (
        <div className="nft-management__pagination">
          <CustomPagination
            total={totalRow}
            current={page}
            onChange={(page) =>
              setState((prevState) => ({ ...prevState, page }))
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default NftsManagement;
