import BigNumber from "bignumber.js";
import { toast } from "react-toastify";

export const arrayMove = (
  arr: Array<any>,
  old_index: number,
  new_index: number
) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str);
  return toast.success("Copy Successfully!", { position: "top-center" });
};

export const scaleHeightChart = (
  val: number | string,
  tick: number | string
) => {
  val = Number(val);
  tick = Number(tick);

  if (tick / val > 1.09) return tick;
  const d = val < 10 ? val * 1.2 : Math.round(tick * 1.1);
  return d;
};

export const shortenBigNumber = (value: string | number) => {
  let result;
  if (new BigNumber(value).gte(1e9)) {
    result = new BigNumber(value).div(1e9).toFixed(0) + "B";
  } else if (new BigNumber(value).gte(1e6)) {
    result = new BigNumber(value).div(1e6).toFixed(0) + "M";
  } else if (new BigNumber(value).gte(1e3)) {
    result = new BigNumber(value).div(1e3).toFixed(0) + "K";
  } else {
    result = value;
  }
  return result;
};

export const isArrayZero = (arr: Array<any>) => {
  const index = arr.findIndex((item) => Number(item) !== 0);
  return index === -1;
};
