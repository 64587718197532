import React, { useCallback, useRef, useState } from "react";
import "./styles.scss";
import { Col, Row } from "antd";
import CustomModal from "../../../components/CustomModal";
import defaultCollectionImg from "../../../assets/images/default-collection-img.png";
import { FileTypeEnum } from "../../../constants/file-type";
import { validFileSize, validFileType } from "../../../helpers/file";
import { useDispatch } from "react-redux";
import { toastError } from "../../../layouts/MainLayout/slice";
import nftApi from "../../../api/nft";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { collectionSchema } from "./schema";
import ActionsModal from "../../../components/ActionsModal";
import { Collection } from "../types";
import { DEFAULT_COLLECTION_IMAGE } from "../../../constants/title";

const MAXIMUM_FILE_SIZE = 5;
const ACCEPTED_FILE_TYPES = [
  FileTypeEnum.PNG,
  FileTypeEnum.GIF,
  FileTypeEnum.WEBP,
  FileTypeEnum.JPEG,
  FileTypeEnum.JPG,
];

type Props = {
  visible: boolean;
  onDismiss: (isSuccess?: boolean) => void;
};

const CreateCollection: React.FC<Props> = ({ visible, onDismiss }) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const [file, setFile] = useState<File>();
  const [statusTx, setStatusTx] = useState<"pending" | "success" | "error">();
  const [showSuccessModalFlag, setShowSuccessModalFlag] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(collectionSchema),
  });

  const clearDataFileInput = useCallback(() => {
    (inputFileRef.current as HTMLInputElement).files = null;
    (inputFileRef.current as HTMLInputElement).value = "";
  }, []);

  const handleFileChange = (e: { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    if (!validFileType(file, ACCEPTED_FILE_TYPES)) {
      dispatch(
        toastError({
          message: `This file type is not supported. You can only upload PNG, JPG, JPEG, WEBP or GIF file!`,
        })
      );
      clearDataFileInput();
      return;
    }

    if (!validFileSize(file, MAXIMUM_FILE_SIZE)) {
      dispatch(
        toastError({
          message: `The file must not be larger than ${MAXIMUM_FILE_SIZE}MB!`,
        })
      );
      clearDataFileInput();
      return;
    }

    const fileUrl = URL.createObjectURL(file);

    setFilePreviewUrl(fileUrl);
    setFile(file);
  };

  const submit = async (formValue: Partial<Collection>) => {
    try {
      setStatusTx("pending");
      setShowSuccessModalFlag(true);

      const fileUploadedUrl = await uploadFile();
      const data: Partial<Collection> = {
        ...removeNullObject(formValue),
        image: fileUploadedUrl,
        shortUrl: `https://hotdrops.com/${formValue.shortUrl}`,
      };
      const res = await nftApi.createCollection(data);
      if (res.status === 201) {
        setStatusTx("success");
      }
    } catch (error) {
      if (
        (error as any)?.response?.data?.message[0]?.name?.constraints[0] ===
        "Collection name has been taken"
      ) {
        dispatch(
          toastError({
            message: "This collection name already exists. Please try again.",
          })
        );
      }
      setShowSuccessModalFlag(false);
      setStatusTx("error");
      console.error(error);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      return {
        metaData: DEFAULT_COLLECTION_IMAGE,
        fileExtension: 'png',
      };
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    const res = await nftApi.uploadFile(formData);
    if (res.status === 201) {
      return res.data.data;
    } else {
      throw new Error("An error occurred while uploading the file.");
    }
  };

  const removeNullObject = (params: any) => {
    return Object.fromEntries(
      Object.entries(params)
        .filter(([_, v]) => v)
        .map(([_, v]) => [_, typeof v === "string" ? v.trim() : v])
    );
  };

  const handleDismissSuccess = () => {
    onDismiss(true);
  };

  return (
    <CustomModal
      visible={visible}
      onDismis={onDismiss}
      width={700}
      customClass="rounded-modal"
      maskClosable
    >
      <div className="create-collection">
        <Row>
          <Col span={14}>
            <div className="create-collection__form">
              <h2 className="create-collection__form-heading">
                Create collection
              </h2>

              <form onSubmit={handleSubmit(submit)} autoComplete={"off"}>
                <div className="create-collection__form-group">
                  <label htmlFor="name">
                    Display name <span className="label-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    placeholder="Enter collection name"
                    {...register("name")}
                    maxLength={150}
                  />
                  <div className="error-message">{errors?.name?.message}</div>
                  <div className="hint">
                    Token name cannot be changed in future
                  </div>
                </div>
                <div className="create-collection__form-group">
                  <label htmlFor="symbol">
                    Symbol <span className="label-required">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="symbol"
                    placeholder="Enter token name"
                    {...register("symbol")}
                    maxLength={50}
                  />
                  <div className="error-message">{errors?.symbol?.message}</div>
                </div>
                <div className="create-collection__form-group">
                  <label htmlFor="description">
                    Description <span>(optional)</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Enter description"
                    {...register("description")}
                    maxLength={400}
                  />
                </div>
                <div className="create-collection__form-group">
                  <label htmlFor="shortUrl">
                    Short url <span className="label-required">*</span>
                  </label>
                  <div className="input-prefix">
                    <div className="prefix">https://hotdrops.com/</div>
                    <input
                      className="form-control"
                      id="shortUrl"
                      placeholder="Enter short url"
                      {...register("shortUrl")}
                      maxLength={50}
                    />
                  </div>
                  <div className="error-message">
                    {errors?.shortUrl?.message}
                  </div>
                  <div className="hint">Will be used as public URL</div>
                </div>
                <input
                  ref={inputFileRef}
                  type="file"
                  onChange={handleFileChange}
                  accept={ACCEPTED_FILE_TYPES.join(",")}
                />
                <div className="create-collection__form-actions">
                  <button className="btn-submit">
                    <span>Create</span>
                  </button>
                </div>
              </form>
            </div>
          </Col>

          <Col span={10}>
            <div className="create-collection__preview-img">
              <img
                src={filePreviewUrl || defaultCollectionImg}
                alt="collection-img"
              />
              <p>
                We recommend an image of at least 300x300. Gifs work too. Max
                5MB.
              </p>
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="btn-choose-file"
                  onClick={() => {
                    inputFileRef.current?.click();
                  }}
                >
                  Choose file
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <ActionsModal
          isOnlyAction={true}
          visible={showSuccessModalFlag}
          status={statusTx}
          title={"SUCCESS!"}
          message={
            statusTx === "pending"
              ? "Collection is being created. Please wait..."
              : "Collection has been created successfully."
          }
          onDismis={handleDismissSuccess}
          onConfirm={handleDismissSuccess}
        />
      </div>
    </CustomModal>
  );
};

export default CreateCollection;
