import React from "react";
import userIcon from "../../assets/icons/user-icon.svg";
import nftIcon from "../../assets/icons/nft-icon-fill.svg";
import blockIcon from "../../assets/icons/block-icon.svg";
import moneyIcon from "../../assets/icons/attach-money.svg";
import "./styles.scss";
import { formatIntBalance } from "../../functions/format";

const initBlock = [
  {
    data: "15000",
    text: "Total creators with min. 1 listings for sale",
    icon: userIcon,
  },
  {
    data: "2950",
    text: "Number of NFTs with min. 1 listing for sale",
    icon: nftIcon,
  },
  {
    data: "1476",
    text: "Total listings for sale",
    icon: blockIcon,
  },
  {
    data: "2950",
    text: "Average list price",
    icon: moneyIcon,
  },
];

const MarketPlace = () => {
  return (
    <div className="market-place-container">
      <div className="block-container">
        {initBlock.map((item) => {
          return (
            <div className="block-item">
              <div className="data">
                <div className="value">{formatIntBalance(item.data, 0)}</div>
                <img src={item.icon} alt="" />
              </div>
              <div className="desc">{item.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MarketPlace;
