/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { PREFIX_TITLE } from '../constants/title';

const useDocumentTitle = (title: any, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} - ${PREFIX_TITLE}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
};

export default useDocumentTitle;
