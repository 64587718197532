import { Col, Row, Tooltip } from "antd";
import { BigNumber } from "ethers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usersApi from "../../../api/users";
import copyIcon from "../../../assets/icons/copy-icon.svg";
import ActionButton from "../../../components/ActionButton";
import ActionsModal from "../../../components/ActionsModal";
import BarChart from "../../../components/BarChart";
import Status from "../../../components/Status";
import { StatusEnum } from "../../../constants/Status";
import { getProvder } from "../../../functions/contract";
import { formatBalance, formatIntBalance } from "../../../functions/format";
import { copyToClipboard } from "../../../functions/utils";
import { shortenAddress } from "../../../helpers/wallet";
import DetailsHeader from "../DetailsHeader";
import { User } from "../types";
import "./styles.scss";

const priceData = {
  labels: ["NFTs owned", "Re-sales", ...Array(2).fill("")],
  datasets: [
    {
      data: [2290, 1500],
      backgroundColor: ["#FF8200", "#FFBD3E"],
    },
  ],
};

const initialAlert = {
  isOpen: false,
  title: "",
  message: "",
  backToListFlag: false,
};

const UserDetails: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpenActiveConfirmModal, setIsOpenActiveConfirmModal] =
    useState(false);
  const [isOpenPauseConfirmModal, setIsOpenPauseConfirmModal] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);
  const [alert, setAlert] = useState(initialAlert);
  const [totalETH, setTotalETH] = useState('0');

  const fetchUserById = async (id: number) => {
    try {
      const {
        data: { data },
      } = await usersApi.getUserById(id);
      setUser(data);
    } catch (error) {
      console.error(error);
      setUser(null);
    }
  };

  const handleActiveUser = async () => {
    try {
      const {
        data: { data },
      } = await usersApi.activeUserById(Number(id));
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have activated this account. A notification email has been sent to the user",
          backToListFlag: false,
        });
        setUser((prevUser) => {
          return (
            prevUser && {
              ...prevUser,
              status: StatusEnum.ACTIVE,
            }
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePauseUser = async () => {
    try {
      const {
        data: { data },
      } = await usersApi.pauseUserById(Number(id));
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have paused this account. A notification email has been sent to the user",
          backToListFlag: false,
        });
        setUser((prevUser) => {
          return (
            prevUser && {
              ...prevUser,
              status: StatusEnum.PAUSED,
            }
          );
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      const {
        data: { data },
      } = await usersApi.deleteUserById(Number(id));
      setIsDeleting(false);
      if (data) {
        setAlert({
          isOpen: true,
          title: "SUCCESS!",
          message:
            "You have deleted this account. A notification email has been sent to the user",
          backToListFlag: true,
        });
      }
    } catch (error) {
      setIsDeleting(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserById(Number(id));
  }, [id]);

  const handleDismisAlert = () => {
    if (alert.backToListFlag) {
      navigate("/users", { state: true });
    } else {
      setAlert(initialAlert);
    }
  };

  const shortenText = (text: string) => {
    if (!text) {
      return "";
    }

    if (text.length <= 30) {
      return text;
    }

    return (
      <Tooltip placement="top" title={text}>
        {`${text.substring(0, 30)}...`}
      </Tooltip>
    );
  };

  useEffect(() => {
    (async () => {
      if (!user?.wallets || !user?.wallets.length) return;
      const provider = getProvder();
      const arrBalance = user?.wallets.map((wallet: string) => {
        return provider.getBalance(wallet);
      });
      const balances = await Promise.all(arrBalance);
      const total = balances.reduce((pre, current) => {
        return BigNumber.from(pre).add(current);
      }, BigNumber.from("0"));

      setTotalETH(formatBalance(total, 18));
    })();
  }, [user?.wallets]);

  return (
    <div className="user-details">
      <DetailsHeader
        username={user?.username}
        email={user?.email}
        status={user?.status}
        onActive={() =>
          user?.status === StatusEnum.PAUSED &&
          setIsOpenActiveConfirmModal(true)
        }
        onPause={() =>
          user?.status === StatusEnum.ACTIVE && setIsOpenPauseConfirmModal(true)
        }
        onBack={() => navigate("/users", { state: true })}
      />

      <Row>
        <Col>
          <h2 className="user-details__wallets-balance">
            Total ETH: {formatIntBalance(totalETH)}
          </h2>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={16}>
          <div className="user-details__tbl-infos-wrapper">
            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{user?.id}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td>{user?.username && shortenText(user?.username)}</td>
                </tr>
                <tr>
                  <td>Full name</td>
                  <td>
                    {!user?.firstName && !user?.lastName
                      ? "N/A"
                      : shortenText(
                          `${user?.firstName || ""} ${user?.lastName || ""}`
                        )}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{user?.email && shortenText(user?.email)}</td>
                </tr>
                <tr>
                  <td>Date of birth</td>
                  <td>
                    {user?.birthday
                      ? moment(user.birthday).format("MM/DD/YYYY")
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>KYC status</td>
                  <td>
                    <Status
                      status={user?.kycStatus || StatusEnum.VERIFIED}
                      customClass="kyc-status"
                    />
                  </td>
                </tr>
                <tr>
                  <td>User status</td>
                  <td>
                    <Status status={user?.status || StatusEnum.ACTIVE} />
                  </td>
                </tr>
                <tr>
                  <td>Transaction history</td>
                  <td>View history</td>
                </tr>
                <tr>
                  <td className="wallet-cell">Wallet address</td>
                  <td>
                    {user?.wallets?.length
                      ? user.wallets.map((wallet, id) => (
                          <p key={id} title={wallet}>
                            <Tooltip placement="top" title={wallet}>
                              {shortenAddress(wallet)}
                            </Tooltip>
                            <img
                              src={copyIcon}
                              alt=""
                              className="copy-icon"
                              onClick={() => copyToClipboard(wallet)}
                            />
                          </p>
                        ))
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>

        <Col span={8}>
          <div className="user-details__chart">
            <BarChart data={priceData} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <ActionButton
            actionName="Delete Account"
            callback={() => setIsOpenDeleteConfirmModal(true)}
            isLoading={isDeleting}
          />
        </Col>
      </Row>

      <ActionsModal
        visible={isOpenActiveConfirmModal}
        onDismis={() => setIsOpenActiveConfirmModal(false)}
        title={"ENABLE THIS ACCOUNT"}
        message={"Are you sure you want to enable this account?"}
        onConfirm={() => {
          setIsOpenActiveConfirmModal(false);
          handleActiveUser();
        }}
      />

      <ActionsModal
        visible={isOpenPauseConfirmModal}
        onDismis={() => setIsOpenPauseConfirmModal(false)}
        title={"PAUSE THIS ACCOUNT"}
        message={"Are you sure you want to pause this account?"}
        onConfirm={() => {
          setIsOpenPauseConfirmModal(false);
          handlePauseUser();
        }}
      />

      <ActionsModal
        visible={isOpenDeleteConfirmModal}
        onDismis={() => setIsOpenDeleteConfirmModal(false)}
        title={"DELETE THIS ACCOUNT"}
        message={"Are you sure you want to permanently delete this account?"}
        onConfirm={() => {
          setIsOpenDeleteConfirmModal(false);
          handleDeleteAccount();
        }}
      />

      <ActionsModal
        isOnlyAction={true}
        visible={alert.isOpen}
        onDismis={handleDismisAlert}
        title={alert.title}
        message={alert.message}
        onConfirm={handleDismisAlert}
      />
    </div>
  );
};

export default UserDetails;
