/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "antd";
import React, { useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import nftApi from "../../../api/nft";
import ethIconImg from "../../../assets/icons/eth.svg";
import ActionsModal from "../../../components/ActionsModal";
import { AppInputAmount } from "../../../components/Base";
import Loading from "../../../components/Loading";
import {
  ACCEPTED_METADATA_TYPES,
  MAXIMUM_METADATA_SIZE,
} from "../../../constants/common";
import {
  getAcceptedFileTypeStr,
  validFileSize,
  validFileType,
} from "../../../helpers/file";
import { toastError } from "../../../layouts/MainLayout/slice";
import { FileCategory } from "../../../types/common";
import { Badge } from "../types";
import { badgeSchema } from "./schema";
import "./styles.scss";

const CreateBadge: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const [filePreviewCategory, setFilePreviewCategory] =
    useState<FileCategory>();
  const [file, setFile] = useState<File | null>();
  const [showSuccessModalFlag, setShowSuccessModalFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(badgeSchema),
  });
  const dataPost = useRef<Omit<Badge, "id"> | null>();
  const watchAllFields = watch();

  const submit = async (formValue: FieldValues) => {
    if (!file) {
      dispatch(
        toastError({
          message: "Please upload file!",
        })
      );
      return;
    }

    try {
      setIsLoading(true);

      const image = (await uploadFile(file)) as string;
      const data: Omit<Badge, "id"> = {
        ...removeNullObject(formValue),
        image,
      };
      dataPost.current = data;
      saveBadge();
    } catch (error) {
      setIsLoading(false);
      dataPost.current = null;
      console.error(error);
    }
  };

  const saveBadge = async () => {
    if (!dataPost.current) {
      return;
    }

    try {
      const res = await nftApi.createBadge(dataPost.current);
      if (res.status === 201) {
        setShowSuccessModalFlag(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dataPost.current = null;
      console.error(error);
    }
  };

  const uploadFile = async (fileToUpload: File) => {
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    const res = await nftApi.uploadFile(formData);
    if (res.status === 201) {
      return res.data.data;
    } else {
      throw new Error("An error occurred while uploading the file.");
    }
  };

  const removeNullObject = (params: FieldValues) => {
    return Object.fromEntries(
      Object.entries(params)
        .filter(([_, v]) => v)
        .map(([_, v]) => [_, typeof v === "string" ? v.trim() : v])
    ) as Omit<Badge, "id">;
  };

  const handleFileChange = (e: { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    if (!validFileType(file, ACCEPTED_METADATA_TYPES)) {
      dispatch(
        toastError({
          message: `This file type is not supported. You can only upload ${getAcceptedFileTypeStr(
            ACCEPTED_METADATA_TYPES
          )} file!`,
        })
      );
      return;
    }

    if (!validFileSize(file, MAXIMUM_METADATA_SIZE)) {
      dispatch(
        toastError({
          message: `The file must not be larger than ${MAXIMUM_METADATA_SIZE}MB!`,
        })
      );
      return;
    }

    const fileCategory = file.type.split("/")[0] as FileCategory;
    const fileUrl = URL.createObjectURL(file);

    setFilePreviewCategory(fileCategory);
    setFilePreviewUrl(fileUrl);
    setFile(file);
  };

  const handleClearFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setFilePreviewUrl("");
    setFile(null);
  };

  const handleDismissSuccess = () => {
    navigate("/badge");
  };

  return (
    <div className="create-nft">
      <div className="create-badge__header">
        <FiArrowLeft
          className="btn-back"
          onClick={() => {
            navigate("/badge");
          }}
        />
        <h3>Create Badge</h3>
      </div>

      <Row gutter={24}>
        <Col xs={24} lg={14}>
          <form
            className="create-badge__form"
            onSubmit={handleSubmit(submit)}
            autoComplete={"off"}
          >
            <div className="create-badge__form-group">
              <label>
                Upload file<span className="label-required">*</span>
              </label>
              <div className="choose-file">
                {filePreviewUrl ? (
                  <div className="file-preview">
                    {filePreviewCategory === "audio" && (
                      <audio src={filePreviewUrl} controls />
                    )}
                    {filePreviewCategory === "image" && (
                      <img src={filePreviewUrl} alt="file-preview" />
                    )}
                    {filePreviewCategory === "video" && (
                      <video src={filePreviewUrl} autoPlay controls />
                    )}

                    <MdClose
                      className="btn-clear-file"
                      onClick={handleClearFileClick}
                    />
                  </div>
                ) : (
                  <>
                    <p>
                      {getAcceptedFileTypeStr(ACCEPTED_METADATA_TYPES)}. Max{" "}
                      {MAXIMUM_METADATA_SIZE}mb.
                    </p>
                    <div className="btn-wrapper">
                      <button
                        type="button"
                        className="btn-choose-file"
                        onClick={() => {
                          inputFileRef.current?.click();
                        }}
                      >
                        Choose file
                      </button>
                    </div>
                  </>
                )}
              </div>
              <input
                ref={inputFileRef}
                type="file"
                onChange={handleFileChange}
                accept={ACCEPTED_METADATA_TYPES.join(",")}
              />
            </div>

            <div className="create-badge__form-group">
              <label htmlFor="price">
                Price<span className="label-required">*</span>
              </label>
              <div className="input-suffix">
                <AppInputAmount
                  className="form-control"
                  placeholder="Enter price for one piece"
                  decimalScale={4}
                  isAllowed={({ value }) => {
                    return value?.split(".")[0].length <= 10;
                  }}
                  {...register("price", {
                    onChange: (e) => setValue("price", e.target.value),
                  })}
                />
                <div className="suffix">
                  <img src={ethIconImg} alt="eth-icon-img" />
                  <span>USDT</span>
                </div>
              </div>
              <div className="error-message">{errors?.price?.message}</div>
            </div>

            <div className="create-badge__form-group">
              <label htmlFor="name">
                Name<span className="label-required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="E.g “After purchasing you'll be able to get the real T-shirt”"
                {...register("name")}
              />
              <div className="error-message">{errors?.name?.message}</div>
            </div>

            <div className="create-badge__form-group">
              <label htmlFor="description">
                Description <span>(Optional)</span>
              </label>
              <textarea
                className="form-control"
                placeholder="E.g “After purchasing you'll be able to get the real T-shirt”"
                {...register("description")}
              />
              <div className="hint hint-small">With preserved line-breaks</div>
            </div>

            <div className="create-badge__form-group">
              <Row gutter={24}>
                <Col span={12}>
                  <label htmlFor="discountRate">
                    Discount rate<span className="label-required">*</span>
                  </label>
                  <div className="input-suffix">
                    <AppInputAmount
                      className="form-control"
                      id="discountRate"
                      decimalScale={1}
                      {...register("discountRate", {
                        onChange: (e) =>
                          setValue("discountRate", e.target.value),
                      })}
                    />
                    <div className="suffix">
                      <span>%</span>
                    </div>
                  </div>
                  <div className="error-message">
                    {errors?.discountRate?.message}
                  </div>
                </Col>
                <Col span={12}>
                  <label htmlFor="amount">
                    Number of copies<span className="label-required">*</span>
                  </label>
                  <AppInputAmount
                    className="form-control"
                    id="amount"
                    decimalScale={0}
                    isAllowed={({ value }) => {
                      return value?.split(".")[0].length <= 5;
                    }}
                    {...register("amount", {
                      onChange: (e) => setValue("amount", e.target.value),
                    })}
                  />
                  <div className="error-message">{errors?.amount?.message}</div>
                  <div className="hint hint-small">Amount of tokens</div>
                </Col>
              </Row>
            </div>

            <div className="create-badge__form-group">
              <label htmlFor="duration">
                Duration<span className="label-required">*</span>
              </label>
              <div className="input-suffix">
                <AppInputAmount
                  className="form-control"
                  id="duration"
                  decimalScale={1}
                  {...register("duration", {
                    onChange: (e) => setValue("duration", e.target.value),
                  })}
                />
                <div className="suffix">
                  <span>Day(s)</span>
                </div>
              </div>
              <div className="error-message">{errors?.duration?.message}</div>
            </div>

            <div className="create-badge__form-actions">
              <button className="btn-submit" disabled={isLoading}>
                {isLoading && <Loading />}
                <span>Create item</span>
              </button>
            </div>
          </form>
        </Col>

        <Col xs={24} lg={6}>
          <div className="create-badge__preview">
            <h4>Preview</h4>

            <div className="create-badge__preview-file">
              {filePreviewUrl && filePreviewCategory === "image" ? (
                <div className="file">
                  <img src={filePreviewUrl} alt="file-preview" />

                  <div className="name">
                    <p>{watchAllFields.name}</p>
                    {/* <img
                      src={ethIconImg}
                      alt="eth-icon-img"
                    /> */}
                  </div>

                  <div className="price">
                    <p>Price</p>
                    <p>{Number(watchAllFields.price) || 0} USDT</p>
                  </div>
                </div>
              ) : (
                <p>Upload file to preview your brand new NFT</p>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <ActionsModal
        isOnlyAction={true}
        visible={showSuccessModalFlag}
        title={"SUCCESS!"}
        message={"New badge added successfully."}
        onDismis={handleDismissSuccess}
        onConfirm={handleDismissSuccess}
      />
    </div>
  );
};

export default CreateBadge;
