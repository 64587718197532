/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import errorIcon from '../../assets/icons/error.svg';
import { RootState } from '../../store';
import HeaderBar from '../HeaderBar';
import SideBar from '../SideBar';
import { clearToastError, toggleCollapsed } from './slice';
import './styles.scss';

export default function MainLayout() {
    const dispatch = useDispatch();
    const layoutSelector = useSelector((state: RootState) => state.layout);
    const [sideBarVisisble, setSideBarVisible] = useState(false);

    const handleToggleCollapsed = () => {
        dispatch(toggleCollapsed());
    }

    const toggleSideBar = () => {
        setSideBarVisible(!sideBarVisisble);
    }

    useEffect(() => {
        if (layoutSelector?.toastMessage) {
            setTimeout(() => {
                dispatch(clearToastError());
            }, 3000)
        }
    }, [layoutSelector?.toastMessage])

    return (
        <div className="page-container">
            <SideBar
                isCollapsed={layoutSelector?.collapsed}
                isSideBarVisible={sideBarVisisble}
                handleSideBar={toggleSideBar}
            />
            <div className={layoutSelector?.collapsed ? 'page-body-wrapper page-collapsed' : 'page-body-wrapper'}>
                <HeaderBar
                    isCollapsed={layoutSelector?.collapsed}
                    handleCollapsed={handleToggleCollapsed}
                    handleSideBar={toggleSideBar}
                />
                <div className="main-panel">
                    <div className={layoutSelector?.toastMessage ? 'top-toast-error toast-visible' : 'top-toast-error'}>
                        {layoutSelector?.toastMessage ? (
                            <>
                                <img src={errorIcon} alt="" /> {layoutSelector?.toastMessage}
                            </>
                        ) : ''}
                    </div>
                    <div className="content-wrapper">
                        <Outlet />
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        </div>
    )
}
