import { Col, Row, Tooltip } from "antd";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import "./styles.scss";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import Tabs from "../Tabs";
import { StatusEnum } from "../../../constants/Status";
import { statusTabs } from "../constants";

type Props = {
  username?: string;
  email?: string;
  status?: StatusEnum;
  avatar?: string;
  onActive?: () => void;
  onPause?: () => void;
  onBack: () => void;
};

const BASE_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

const DetailsHeader: React.FC<Props> = ({
  username,
  email,
  status,
  avatar,
  onActive,
  onPause,
  onBack,
}) => {
  const shortenText = (text: string) => {
    if (!text) {
      return "";
    }

    if (text.length <= 30) {
      return text;
    }

    return (
      <Tooltip placement="top" title={text}>
        {`${text.substring(0, 30)}...`}
      </Tooltip>
    );
  };

  return (
    <div className="details-header">
      <Row>
        <Col span={6}>
          <div className="details-header__header-left">
            <FiArrowLeft className="btn-back" onClick={onBack} />
            <img
              src={
                avatar
                  ? avatar?.indexOf("http") === 0
                    ? avatar
                    : `${BASE_URL}${avatar}`
                  : defaultAvatar
              }
              alt="user-avatar"
              className="avatar"
            />
            <div className="text">
              <p className="user-id">{username && shortenText(username)}</p>
              <p className="email">{email && shortenText(email)}</p>
            </div>
          </div>
        </Col>

        <Col span={6} offset={12}>
          <div className="details-header__header-right">
            {status && (
              <Tabs
                currentTab={status}
                onCurrentTabChange={(tab) => {
                  if (tab === StatusEnum.ACTIVE && onActive) {
                    onActive();
                  }
                  if (tab === StatusEnum.PAUSED && onPause) {
                    onPause();
                  }
                }}
                tabs={statusTabs}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DetailsHeader;
