import { FileTypeEnum } from './file-type';

export const MAXIMUM_METADATA_SIZE = 100;
export const ACCEPTED_METADATA_TYPES = [
    FileTypeEnum.PNG,
    FileTypeEnum.JPG,
    FileTypeEnum.JPEG,
    FileTypeEnum.GIF,
    FileTypeEnum.WEBP,
    FileTypeEnum.MP3,
    FileTypeEnum.MP4,
];
