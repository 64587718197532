import { Badge } from "../pages/BadgeManagement/types";
import { Collection, Nft } from "../pages/NftManagement/types";
import { baseRequest } from "./base-request";

const nftApi = {
    uploadFile: async (formData: FormData) => {
        const endpoint = '/api/v1/nft/ipfs';
        return baseRequest('post', endpoint, formData);
    },
    uploadFileCover: async (formData: FormData) => {
        const endpoint = '/api/v1/nft/cover-ipfs';
        return baseRequest('post', endpoint, formData);
    },
    // createNft: async (data) => {

    // },
    createCollection: async (data: Partial<Collection>) => {
        const endpoint = '/api/v1/nft/collection';
        return baseRequest('post', endpoint, data);
    },
    fetchCollections: async () => {
        const endpoint = '/api/v1/nft/collection';
        return baseRequest('get', endpoint);
    },
    createNft: async (data: Partial<Nft>) => {
        const endpoint = '/api/v1/nft';
        return baseRequest('post', endpoint, data);
    },
    createBadge: async (data: Omit<Badge, 'id'>) => {
        const endpoint = '/api/v1/nft/badge';
        return baseRequest('post', endpoint, data);
    },
    getNftDetails: async (id: string | number) => {
        const endpoint = `api/v1/nft/admin/${id}`;
        return baseRequest('get', endpoint);
    },
    handleHideNft: async (id: string | number) => {
        const endpoint = `api/v1/nft/admin/${id}`;
        return baseRequest('put', endpoint);
    },
};

export default nftApi;