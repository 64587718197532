import React from "react";
import "./styles.scss";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

export default function PieChart(props: any) {
  const { data, className } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
      labels: {
        padding: 30,
        boxWidth: 16,
        fontColor: "#ACACAC",
        fontSize: 16,
        fontFamily: "Montserrat",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: true,
    },
  };

  return (
    <div className={`doughnut-chart-container ${className}`}>
      {data.datasets[0].data.filter((item: string) => Number(item)).length ? (
        <Pie data={data} options={options} />
      ) : (
        <div className="no-data-container">No data</div>
      )}
    </div>
  );
}
