import { Contract, ethers } from "ethers";
import { ChainId } from "../configs/Connect/Configs.chainId";
import rpc from "../configs/Connect/Configs.rpc";
import { HOTDROPS_CONTRACT } from "../constants/addresses";
import { getContract } from "./getContract";
import ADMIN_ABI from "../constants/abis/hotdrops.json";

const DEFAULT_CHAIN = process.env.REACT_APP_IS_PROD
  ? ChainId.MAINNET
  : ChainId.RINKEBY;

export const getProvder = () => {
  return ethers.getDefaultProvider(rpc[DEFAULT_CHAIN]);
}

export const getAdminContract = (): Contract => {
  const provider = ethers.getDefaultProvider(rpc[DEFAULT_CHAIN]);

  return getContract(HOTDROPS_CONTRACT[DEFAULT_CHAIN], ADMIN_ABI, provider);
};
