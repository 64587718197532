/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import moment from 'moment';

const REGEX_USERNAME = /^[a-zA-Z0-9><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w]+$/;
const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-zA-Z])([><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s])*$/;
const REGEX_TWITTER_URL = /^(http(?:s)?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9][a-zA-Z0-9_%=?.@!#$%\^&*(){}`~#\-+:;\"\'\s\/><,\[\]\\\|]*)$/;

export const createCreatorSchema = yup.object().shape({
    username: yup
        .string()
        .required('This field is required.')
        .matches(REGEX_USERNAME, 'The username must not contain space'),
    password: yup
        .string()
        .required('This field is required.')
        .min(8, 'Must contain 8-16 characters')
        .max(16, 'Must contain 8-16 characters')
        .matches(REGEX_PASSWORD, 'Must contain at least 1 number and letter'),
    firstName: yup
        .string()
        .trim()
        .required('This field is required.'),
    lastName: yup
        .string()
        .trim()
        .required('This field is required.'),
    email: yup
        .string()
        .required('This field is required.')
        .email('Please enter a valid email address.'),
    twitterUrl: yup
        .string()
        .transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        })
        .nullable()
        .matches(REGEX_TWITTER_URL, 'Twitter URL is invalid'),
    birthday: yup
        .string()
        .required('This field is required.')
        .test('birthday', 'The creator must be 18 years old or older.', (date) => moment().diff(moment(date), "years") >= 18),
    weight: yup
        .number()
        .positive('The weight field must be a positive number')
        .transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        })
        .nullable()
        .typeError('The weight field must be a number'),
    commission: yup
        .string()
        .required("This field is required")
        .test({
            test: (val) => Number(val) <= 100,
            message: "The commission must be less than or equal to 100",
            name: "max",
        }),
});
