import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import Status from '../Status';
import './styles.scss';
import { Column } from './types';
import FilterButton from './FilterButton';
import { Tooltip } from 'antd';
import moment from 'moment';
import { formatIntBalance } from "../../functions/format";

type Props = {
    columns: Column[];
    data: any[];
    isLoading?: boolean;
    clickable?: boolean;
    path?: string;
    query?: string;
    filterValue?: any;
    onFilter?: (filterParams: any) => void;
    onRowClick?: (id: number) => void;
};

const CustomTable: React.FC<Props> = ({
    columns,
    data,
    isLoading,
    clickable,
    path,
    query,
    filterValue,
    onFilter,
    onRowClick,
}) => {
    const navigate = useNavigate();

    const getColumnValue = (item: any, column: Column) => {
        if (column.customDisplay) {
            return shortenText(column.customDisplay(item));
        }

        switch (column.type) {
            case 'number':
                return formatIntBalance(item[column.field], 2);
            case 'date':
                return moment(item[column.field]).format('MM/DD/YYYY');
            case 'status':
                return (
                    <Status
                        status={item[column.field]}
                        customClass={column.customClass}
                    />
                );
            default:
                return shortenText(item[column.field]);
        }
    };

    const shortenText = (text?: string) => {
        if (!text) {
            return '';
        }

        if (text.length <= 30 || typeof text !== 'string') {
            return text;
        }

        return (
            <Tooltip placement="top" title={text} className="tooltip">
                {`${text.substring(0, 30)}...`}
            </Tooltip>
        );
    };

    return (
        <div className="custom-table">
            {isLoading && (
                <div className="custom-table__loading-wrapper">
                    <Loading />
                </div>
            )}

            <table className="custom-table__table">
                <thead>
                    <tr>
                        {columns.map(({ label, filter, field, width }, index) => (
                            <th key={index} className="custom-table__header" style={{ width: width || 'auto' }}>
                                <span>{label}</span>
                                {filter && (
                                    <FilterButton
                                        filter={filter}
                                        filterParams={filterValue[field]}
                                        onFilter={(filters) => {
                                            if (!onFilter) return;
                                            onFilter({
                                                ...filterValue,
                                                [field]: filters,
                                            })
                                        }}
                                    />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {data.length ? (
                        data.map((item, index) => (
                            <tr key={index} className="custom-table__rows">
                                {columns.map((column) => (
                                    <td className="custom-table__cells">
                                        {getColumnValue(item, column)}
                                    </td>
                                ))}
                                {clickable && (
                                    <td
                                        colSpan={columns.length}
                                        className="tr-mask"
                                        onClick={() => {
                                            if (onRowClick) {
                                                onRowClick(item.id);
                                            } else if (path) {
                                                navigate(`${path}/${item.id}`);
                                            }
                                        }}
                                    />
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr className="custom-table__rows no-data">
                            <td colSpan={columns.length}>
                                {query ? 'No search results' : 'No Data'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CustomTable;
