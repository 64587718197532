import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logOut } from '../../pages/Login/slice';
import { PATH } from '../../constants/paths';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import { MdFormatLineSpacing } from 'react-icons/md';
import defaultAvatarImg from '../../assets/images/default-avatar.png';

const HeaderBar = (props: any) => {
    const { isCollapsed, handleCollapsed, handleSideBar } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef<any>(null);

    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

    const toggleProfileDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    }

    const onLogOut = () => {
        dispatch(logOut({ onSuccess: () => navigate(PATH.LOGIN) }));
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setIsProfileDropdownOpen(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [])

    return (
        <nav className={isCollapsed ? 'navbar navbar-collapsed' : 'navbar'}>
            <div className="navbar-menu-wrapper">
                <button className="navbar-toggler navbar-toogler-left" type="button" onClick={handleCollapsed}>
                    <FaBars />
                </button>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item" ref={ref}>
                        <div className="navbar-profile" onClick={toggleProfileDropdown}>
                            <img src={defaultAvatarImg} alt="profile-img" className="profile-img" />
                            {/* <div className="navbar-profile-name">{authSelector?.currentUser?.name}</div>
                            <FaCaretDown className="icon-caret-down" /> */}
                        </div>
                        {isProfileDropdownOpen && (
                            <div className="navbar-dropdown">
                                <div className="navbar-dropdown-title">Profile</div>
                                <div className="dropdown-divider"></div>
                                <div className="dropdown-item" onClick={onLogOut}>
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon">
                                            <FaSignOutAlt className="icon-signout" />
                                        </div>
                                    </div>
                                    <div className="preview-item-content">Log Out</div>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toogler-right" type="button" onClick={handleSideBar}>
                    <MdFormatLineSpacing />
                </button>
            </div>
        </nav>
    )
}

export default HeaderBar
