import { Col, Row } from "antd";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import statsApi from "../../api/stats";
import blockIcon from "../../assets/icons/block-icon.svg";
import nftIcon from "../../assets/icons/nft-icon.svg";
import userIcon from "../../assets/icons/user-icon.svg";
import BarChart from "../../components/BarChart";
import DoughnutChart from "../../components/DoughnutChart";
import { formatBalance, formatIntBalance } from "../../functions/format";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import StatCard from "./StatCard";
import "./styles.scss";
import Trending from "./Trending";
import { IBlockData, IStatResponse, TFilter } from "./type";

const filterOption = [
  {
    name: "All Time",
    value: "",
  },
  {
    name: "Last 24h",
    value: "last-24hours",
  },
  {
    name: "Last Week",
    value: "last-week",
  },
  {
    name: "Last 28 Days",
    value: "last28-days",
  },
  {
    name: "Last Year",
    value: "last-year",
  },
];

const initBlock: IBlockData[] = [
  {
    type: "primary",
    data: "0",
    text: "Total NFTs sold",
    icon: nftIcon,
  },
  {
    type: "default",
    data: "0",
    text: "Total number of user accounts",
    icon: userIcon,
  },
  {
    type: "default",
    data: "0",
    text: "Number of wallets with min. 1 NFT purchase",
    icon: blockIcon,
  },
  {
    type: "default",
    data: "0",
    text: "Number of wallets with min. 1 NFT for sale",
    icon: blockIcon,
  },
];

const initGrossData = {
  labels: ["Company split", "Creator split"],
  datasets: [
    {
      data: ["0", "0"],
      backgroundColor: ["#FF3E3E", "#FF8200"],
    },
  ],
};

const initSaleData = {
  labels: ["Total sales", "Top sales", "Recent sales", ...Array(1).fill("")],
  datasets: [
    {
      data: ["0", "0", "0"],
      backgroundColor: ["#FF3E3E", "#FF8200", "#FFBD3E"],
    },
  ],
};

const initPriceData = {
  labels: ["Total sales price", "Avg sales price", ...Array(2).fill("")],
  datasets: [
    {
      data: ["0", "0"],
      backgroundColor: ["#FF8200", "#FFBD3E"],
    },
  ],
};

const initTrendingData = [
  { search: "N/A", tag: "N/A" },
  { search: "N/A", tag: "N/A" },
  { search: "N/A", tag: "N/A" },
];

const naTopTrending = [
  {
    keyword: "N/A",
    times: 0,
  },
  {
    keyword: "N/A",
    times: 0,
  },
  {
    keyword: "N/A",
    times: 0,
  },
];

export default function Dashboard() {
  useDocumentTitle("Dashboard", true);

  const [filter, setFilter] = useState<TFilter>("");
  const [blockData, setBlockData] = useState<IBlockData[]>(initBlock);
  const [grossData, setGrossData] = useState(initGrossData);
  const [saleData, setSaleData] = useState(initSaleData);
  const [priceData, setPriceData] = useState(initPriceData);
  const [trendingData, setTrendingData] = useState(initTrendingData);

  useEffect(() => {
    (async () => {
      try {
        const resStat = await statsApi.getStat({ query: filter });
        //Stat Data
        if (resStat.status === 200) {
          const resStatData: IStatResponse = resStat.data.data;
          const mapResStatData = [
            resStatData.totalNftSold,
            resStatData.numberUsers,
            resStatData.totalWalletPurchase,
            resStatData.totalWalletSale,
          ];

          const mapData = initBlock.map((item: IBlockData, index: number) => {
            return {
              ...item,
              data: formatIntBalance(mapResStatData[index], 0),
            };
          });

          const sale = [
            new BigNumber(formatBalance(resStatData.totalSales, 6)).toFixed(2),
            new BigNumber(formatBalance(resStatData.topSales, 6)).toFixed(2),
            new BigNumber(formatBalance(resStatData.recentSales, 6)).toFixed(2),
          ];
          const price = [
            new BigNumber(
              formatBalance(resStatData.totalSalesPrice, 6)
            ).toFixed(2),
            new BigNumber(formatBalance(resStatData.avgSalesPrice, 6)).toFixed(
              2
            ),
          ];

          const searchTreding = [...resStatData.topTerm, ...naTopTrending]
            .slice(0, 3)
            .map((item) => {
              return {
                search: item.keyword,
              };
            });

          const tagTrending = [...resStatData.topTags, ...naTopTrending]
            .slice(0, 3)
            .map((item) => {
              return {
                tag: item.keyword,
              };
            });

          const dataTrending = [...Array(3)].map((item, index) => {
            return {
              search: searchTreding[index].search,
              tag: tagTrending[index].tag,
            };
          });

          setTrendingData(dataTrending);

          setSaleData({
            ...initSaleData,
            datasets: [
              {
                data: sale,
                backgroundColor: initSaleData.datasets[0].backgroundColor,
              },
            ],
          });

          setPriceData({
            ...initPriceData,
            datasets: [
              {
                data: price,
                backgroundColor: initPriceData.datasets[0].backgroundColor,
              },
            ],
          });

          setBlockData(mapData);
        }

        //Gross Data
        let resGrossData = resStat.data.data.gross;
        resGrossData = {
          company: new BigNumber(resGrossData.company)
            .div(new BigNumber(resGrossData.company).plus(resGrossData.creator))
            .times(100)
            .toFixed(2),
          creator: new BigNumber(resGrossData.creator)
            .div(new BigNumber(resGrossData.company).plus(resGrossData.creator))
            .times(100)
            .toFixed(2),
        };
        const grossData = {
          labels: ["Company split", "Creator split"],
          datasets: [
            {
              data: [
                resGrossData.company as string,
                resGrossData.creator as string,
              ],
              backgroundColor: ["#FF3E3E", "#FF8200"],
            },
          ],
        };
        setGrossData(grossData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [filter]);

  return (
    <div className="dashboard-page">
      <div className="dashboard-page__custom-select">
        <select onChange={(e) => setFilter(e.target.value as TFilter)}>
          {filterOption.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
      <Row gutter={24} className="dashboard-page__row">
        {blockData.map((item: IBlockData, index: number) => {
          return (
            <Col xs={24} sm={12} lg={6} key={index}>
              <StatCard
                type={item.type}
                data={item.data}
                text={item.text}
                icon={item.icon}
              />
            </Col>
          );
        })}
      </Row>
      <Row gutter={24} className="dashboard-page__row">
        <Col xs={24} lg={12}>
          <BarChart data={saleData} />
        </Col>
        <Col xs={24} lg={12}>
          <BarChart data={priceData} />
        </Col>
      </Row>
      <Row gutter={24} className="dashboard-page__row">
        <Col xs={24} lg={12}>
          <DoughnutChart data={grossData} />
        </Col>
        <Col xs={24} lg={12}>
          <Trending data={trendingData} />
        </Col>
      </Row>
    </div>
  );
}
