/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row, Select, Switch, Tooltip } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import creatorsApi from "../../../api/creators";
import nftApi from "../../../api/nft";
import addIconImg from "../../../assets/icons/add.svg";
import usdtIcon from "../../../assets/icons/usdt.svg";
import hotdropIconImg from "../../../assets/icons/hotdrop.svg";
import musicIcon from "../../../assets/icons/music-icon.svg";
import ActionsModal from "../../../components/ActionsModal";
import { AppInputAmount } from "../../../components/Base/AppInputAmount";
import {
  ACCEPTED_METADATA_TYPES,
  MAXIMUM_METADATA_SIZE,
} from "../../../constants/common";
import { parseBalance } from "../../../functions/format";
import { arrayMove } from "../../../functions/utils";
import {
  getAcceptedFileTypeStr,
  validFileSize,
  validFileType,
} from "../../../helpers/file";
import { FileCategory } from "../../../types/common";
import { Creator } from "../../UserManagement/types";
import {
  ACCEPTED_IMAGE_COVER_TYPES,
  MAXIMUM_IMAGE_COVER_SIZE,
  NFT_CATEGORIES,
} from "../constants";
import CreateCollection from "../CreateCollection";
import ListCollectionModal from "../ListCollectionModal";
import { Collection, Nft } from "../types";
import { nftSchema } from "./schema";
import "./styles.scss";
import { toastError } from "../../../layouts/MainLayout/slice";
import { useDispatch } from "react-redux";

const CreateNft: React.FC = () => {
  const navigate = useNavigate();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const inputFileCoverRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const [showCreateCollectionModalFlag, setShowCreateCollectionModalFlag] =
    useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const [filePreviewCategory, setFilePreviewCategory] =
    useState<FileCategory>();
  const [file, setFile] = useState<File | null>();
  const [fileCoverPreviewUrl, setFileCoverPreviewUrl] = useState("");
  const [fileCover, setFileCover] = useState<File | null>();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<Collection>();
  const [refreshCollections, setRefreshCollections] = useState(false);
  const [showSuccessModalFlag, setShowSuccessModalFlag] = useState(false);
  const [isShowWarning, setShowWarningMsg] = useState({
    categories: false,
  });
  const [isOpenCategories, setIsOpenCategories] = useState<boolean>(false);
  const [isOpenCollectionModal, setIsOpenCollectionModal] =
    useState<boolean>(false);

  const [statusTx, setStatusTx] = useState<"success" | "pending" | "error">();

  const [msgErrorFile, setMsgErrorFile] = useState({
    file: "",
    coverFile: "",
  });

  const { Option } = Select;

  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(nftSchema),
  });
  const watchAllFields = watch();
  const [creators, setCreators] = useState<Creator[]>([]);

  const fetchCollections = async () => {
    try {
      const res = await nftApi.fetchCollections();
      if (res.status === 200) {
        setCollections(res.data.data);
        setSelectedCollection(res.data.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, [refreshCollections]);

  const fetchAllCreators = async () => {
    try {
      const res = await creatorsApi.getAllCreators();
      if (res.status === 200) {
        setCreators(res.data.data);
      }
    } catch (error) {
      setCreators([]);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllCreators();
  }, []);

  const handleFileChange = (e: { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    if (!validFileType(file, ACCEPTED_METADATA_TYPES)) {
      setMsgErrorFile({
        ...msgErrorFile,
        file: `This file type is not supported. You can only upload ${getAcceptedFileTypeStr(
          ACCEPTED_METADATA_TYPES
        )} file!`,
      });
      return;
    }

    if (!validFileSize(file, MAXIMUM_METADATA_SIZE)) {
      setMsgErrorFile({
        ...msgErrorFile,
        file: `The file must not be larger than ${MAXIMUM_METADATA_SIZE}MB!`,
      });
      return;
    }

    const fileCategory = file.type.split("/")[0] as FileCategory;
    const fileUrl = URL.createObjectURL(file);

    if (fileCategory === "image") {
      handleClearFileCoverClick();
    }
    setMsgErrorFile({
      ...msgErrorFile,
      file: "",
    });
    setFilePreviewCategory(fileCategory);
    setFilePreviewUrl(fileUrl);
    setFile(file);
  };

  const handleClearFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setFilePreviewUrl("");
    setFile(null);
  };

  const handleFileCoverChange = (e: { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    if (!validFileType(file, ACCEPTED_IMAGE_COVER_TYPES)) {
      setMsgErrorFile({
        ...msgErrorFile,
        coverFile: `This file type is not supported. You can only upload ${getAcceptedFileTypeStr(
          ACCEPTED_IMAGE_COVER_TYPES
        )} file!`,
      });
      return;
    }

    if (!validFileSize(file, MAXIMUM_IMAGE_COVER_SIZE)) {
      setMsgErrorFile({
        ...msgErrorFile,
        coverFile: `The file must not be larger than ${MAXIMUM_IMAGE_COVER_SIZE}MB!`,
      });
      return;
    }

    const fileUrl = URL.createObjectURL(file);
    setFileCoverPreviewUrl(fileUrl);
    setFileCover(file);
    setMsgErrorFile({
      ...msgErrorFile,
      coverFile: "",
    });
  };

  const handleClearFileCoverClick = () => {
    if (inputFileCoverRef.current) {
      inputFileCoverRef.current.value = "";
    }
    setFileCoverPreviewUrl("");
    setFileCover(null);
  };

  const submit = async (formValue: Partial<Nft>) => {
    if (!file) {
      setMsgErrorFile({
        ...msgErrorFile,
        file: "Please upload file!",
      });
      return;
    }

    if (file && filePreviewCategory !== "image" && !fileCover) {
      setMsgErrorFile({
        ...msgErrorFile,
        coverFile: "Please upload cover image!",
      });
      return;
    }

    try {
      setShowSuccessModalFlag(true);
      setStatusTx("pending");
      const data: Partial<Nft> = {
        ...removeNullObject(formValue),
        price: formValue.putOnMarketPlace
          ? parseBalance(formValue.price || "0").toString()
          : "",
        putOnMarketPlace: formValue.putOnMarketPlace || false,
        unlockOncePurchased: formValue.unlockOncePurchasedFlag
          ? formValue.unlockOncePurchased
          : "",
        collectionId: selectedCollection?.id,
        amount: formValue.amount,
        royalties: Number(formValue.royalties),
      };
      delete data.unlockOncePurchasedFlag;

      if (file && filePreviewCategory === "image") {
        const fileUploadedUrl = await uploadFile(file, "file");
        data.metaData = fileUploadedUrl.metaData;
        data.fileExtension = fileUploadedUrl.fileExtension;
      } else if (file && fileCover) {
        const [fileUploadedUrl, fileCoverUploadedUrl] = await Promise.all([
          uploadFile(file, "file"),
          uploadFile(fileCover, "file-cover"),
        ]);
        data.metaData = fileUploadedUrl.metaData;
        data.cover = fileCoverUploadedUrl.metaData;
        data.fileExtension = fileUploadedUrl.fileExtension;
      }

      const res = await nftApi.createNft(data);
      if (res.status === 201) {
        setStatusTx("success");
      }
    } catch (error: any) {
      setShowSuccessModalFlag(false);
      setStatusTx("error");
      dispatch(toastError({ message: error?.response?.data.message }));
      console.error({ error });
    }
  };

  const uploadFile = async (
    fileToUpload: File,
    fileName: "file" | "file-cover"
  ) => {
    const formData = new FormData();
    formData.append(fileName, fileToUpload, fileToUpload.name);

    const res =
      fileName === "file"
        ? await nftApi.uploadFile(formData)
        : await nftApi.uploadFileCover(formData);
    if (res.status === 201) {
      return res.data.data;
    } else {
      throw new Error("An error occurred while uploading the file.");
    }
  };

  const removeNullObject = (params: any) => {
    return Object.fromEntries(
      Object.entries(params)
        .filter(([_, v]) => v)
        .map(([_, v]) => [_, typeof v === "string" ? v.trim() : v])
    );
  };

  const handleDismissSuccess = () => {
    navigate("/nfts");
  };

  const nftCategoryOptions = NFT_CATEGORIES.map((nftCategory) => (
    <Option key={nftCategory.value}>{nftCategory.label}</Option>
  ));

  const creatorOptions = useMemo(
    () =>
      creators.map((creator: Creator) => (
        <Option key={creator.id}>
          {creator.id} - {creator.firstName || ""} {creator.lastName || ""}
        </Option>
      )),
    [creators]
  );

  useEffect(() => {
    if (isShowWarning.categories) {
      setIsOpenCategories(false);
    }
  }, [isShowWarning.categories]);

  return (
    <div className="create-nft">
      <div className="create-nft__header">
        <FiArrowLeft
          className="btn-back"
          onClick={() => {
            navigate("/nfts");
          }}
        />
        <h3>Create NFT</h3>
      </div>

      <Row gutter={24}>
        <Col xs={24} lg={14}>
          <form
            className="create-nft__form"
            onSubmit={handleSubmit(submit)}
            autoComplete={"off"}
          >
            <div className="create-nft__form-group">
              <label>
                Upload file<span className="label-required">*</span>
              </label>
              <div className="choose-file">
                {filePreviewUrl ? (
                  <div className="file-preview">
                    {filePreviewCategory === "audio" && (
                      <audio src={filePreviewUrl} controls />
                    )}
                    {filePreviewCategory === "image" && (
                      <img src={filePreviewUrl} alt="file-preview" />
                    )}
                    {filePreviewCategory === "video" && (
                      <video src={filePreviewUrl} autoPlay controls />
                    )}

                    <MdClose
                      className="btn-clear-file"
                      onClick={handleClearFileClick}
                    />
                  </div>
                ) : (
                  <>
                    <p>
                      {getAcceptedFileTypeStr(ACCEPTED_METADATA_TYPES)}. Max{" "}
                      {MAXIMUM_METADATA_SIZE}MB.
                    </p>
                    <div className="btn-wrapper">
                      <button
                        type="button"
                        className="btn-choose-file"
                        onClick={() => {
                          inputFileRef.current?.click();
                        }}
                      >
                        Choose file
                      </button>
                    </div>
                  </>
                )}
              </div>
              <input
                ref={inputFileRef}
                type="file"
                onChange={handleFileChange}
                accept={ACCEPTED_METADATA_TYPES.join(",")}
              />
              <div className="error-message">{msgErrorFile.file}</div>
            </div>

            {filePreviewUrl && filePreviewCategory !== "image" && (
              <div className="create-nft__form-group">
                <label>
                  Upload cover<span className="label-required">*</span>
                </label>
                <div className="choose-file">
                  {fileCoverPreviewUrl ? (
                    <div className="file-preview">
                      <img src={fileCoverPreviewUrl} alt="file-preview" />
                      <MdClose
                        className="btn-clear-file"
                        onClick={handleClearFileCoverClick}
                      />
                    </div>
                  ) : (
                    <>
                      <p>
                        {getAcceptedFileTypeStr(ACCEPTED_IMAGE_COVER_TYPES)}.
                        Max {MAXIMUM_IMAGE_COVER_SIZE}
                        MB.
                      </p>
                      <div className="btn-wrapper">
                        <button
                          type="button"
                          className="btn-choose-file"
                          onClick={() => {
                            inputFileCoverRef.current?.click();
                          }}
                        >
                          Choose file
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <input
                  ref={inputFileCoverRef}
                  type="file"
                  onChange={handleFileCoverChange}
                  accept={ACCEPTED_IMAGE_COVER_TYPES.join(",")}
                />
                <div className="error-message">{msgErrorFile.coverFile}</div>
              </div>
            )}
            <div className="create-nft__form-group">
              <div className="inline">
                <label htmlFor="putOnMarketPlace">Put on marketplace</label>
                <Controller
                  control={control}
                  name="putOnMarketPlace"
                  render={({ field }) => (
                    <Switch
                      id="putOnMarketPlace"
                      className="btn-switch"
                      onChange={(e) => field.onChange(e)}
                      checked={field.value}
                    />
                  )}
                />
              </div>
              <div className="hint">
                {watchAllFields.putOnMarketPlace
                  ? "Enter price to allow users instantly purchase your NFT"
                  : "Put your new NFT on Hot Drops's marketplace"}
              </div>
            </div>
            {watchAllFields.putOnMarketPlace && (
              <div className="create-nft__form-group">
                <label htmlFor="price">
                  Price <span className="label-required">*</span>
                </label>
                <div className="input-suffix">
                  <AppInputAmount
                    className="form-control"
                    placeholder="Enter price for one piece"
                    decimalScale={4}
                    isAllowed={({ value }) => {
                      return value?.split(".")[0].length <= 10;
                    }}
                    {...register("price", {
                      onChange: (e) => setValue("price", e.target.value),
                    })}
                  />
                  <div className="suffix">
                    <img src={usdtIcon} alt="eth-icon-img" />
                    <span>USDT</span>
                  </div>
                </div>
                <div className="error-message">{errors?.price?.message}</div>
              </div>
            )}

            <div className="create-nft__form-group">
              <div className="inline">
                <label htmlFor="unlockOncePurchased">
                  Unlock once purchased
                </label>
                <Controller
                  control={control}
                  name="unlockOncePurchasedFlag"
                  render={({ field }) => (
                    <Switch
                      id="unlockOncePurchasedFlag"
                      className="btn-switch"
                      onChange={(e) => field.onChange(e)}
                      checked={field.value}
                    />
                  )}
                />
              </div>
              <div className="hint">
                Content will be unlocked after successful transaction
              </div>
            </div>
            <div className="error-message">
              {errors?.unlockOncePurchasedFlag?.message}
            </div>

            {watchAllFields.unlockOncePurchasedFlag && (
              <div className="create-nft__form-group">
                <textarea
                  className="form-control"
                  placeholder="Digital key, code to redeem or link to a file..."
                  maxLength={150}
                  {...register("unlockOncePurchased")}
                />
                <div className="error-message">
                  {errors?.unlockOncePurchased?.message}
                </div>
                <div className="hint hint-small">Markdown is supported</div>
              </div>
            )}

            <div className="create-nft__form-group">
              <div className="create-nft__form-group_label-collections">
                <label>Choose collection</label>
                {collections.length > 5 && (
                  <label
                    className="view-collection"
                    onClick={() => setIsOpenCollectionModal(true)}
                  >
                    View all collections
                  </label>
                )}
              </div>

              <div className="collections">
                <div className="collections__item">
                  <div
                    className="inner"
                    onClick={() => {
                      setShowCreateCollectionModalFlag(true);
                    }}
                  >
                    <img src={addIconImg} alt="add-icon-img" />
                    <p>Create</p>
                  </div>
                </div>

                {(collections.length > 5
                  ? collections.slice(0, 5)
                  : collections
                ).map((collection: any) => (
                  <div
                    key={collection.id}
                    className={`collections__item${
                      selectedCollection?.id === collection.id
                        ? " collections__item--selected"
                        : ""
                    }`}
                  >
                    <div
                      className="inner"
                      onClick={() => {
                        setSelectedCollection(collection);
                      }}
                    >
                      <img src={collection.image} alt="hotdrop-icon-img" />
                      <p title={collection.name}>{collection.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="create-nft__form-group">
              <label htmlFor="categories">
                Categories<span className="label-required">*</span>
              </label>
              <Controller
                control={control}
                name="categories"
                render={({ field }) => (
                  <Select
                    open={isOpenCategories}
                    mode="multiple"
                    className="ant-select-customize-input multiple"
                    dropdownClassName="custom-dropdown"
                    placeholder="Select a category"
                    onClick={() => {
                      if (!isShowWarning.categories) {
                        setIsOpenCategories(true);
                      }
                    }}
                    onBlur={() => setIsOpenCategories(false)}
                    onChange={(e) => {
                      if (e?.length > 3) {
                        return setShowWarningMsg({ categories: true });
                      } else {
                        setShowWarningMsg({ categories: false });
                      }
                      field.onChange(e);
                    }}
                    value={field.value}
                  >
                    {nftCategoryOptions}
                  </Select>
                )}
              />
              {isShowWarning.categories && (
                <div className="warning-message">
                  You can select a maximum of three categories
                </div>
              )}
              <div className="error-message">{errors?.categories?.message}</div>
            </div>

            <div className="create-nft__form-group">
              <label htmlFor="creatorId">
                Creator<span className="label-required">*</span>
              </label>
              <Controller
                control={control}
                name="creatorId"
                render={({ field }) => (
                  <Select
                    className="ant-select-customize-input single"
                    dropdownClassName="custom-dropdown"
                    placeholder="Select a creator"
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                  >
                    {creatorOptions}
                  </Select>
                )}
              />
              <div className="error-message">{errors?.creatorId?.message}</div>
            </div>

            <div className="create-nft__form-group">
              <label htmlFor="name">
                Name<span className="label-required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="E.g “After purchasing you'll be able to get the real T-shirt”"
                maxLength={150}
                {...register("name")}
              />
              <div className="error-message">{errors?.name?.message}</div>
            </div>

            <div className="create-nft__form-group">
              <label htmlFor="description">
                Description <span>(Optional)</span>
              </label>
              <textarea
                className="form-control"
                placeholder="E.g “After purchasing you'll be able to get the real T-shirt”"
                maxLength={400}
                {...register("description")}
              />
              <div className="hint hint-small">With preserved line-breaks</div>
            </div>

            <div className="create-nft__form-group">
              <Row gutter={24}>
                <Col span={12}>
                  <label htmlFor="royalties">
                    Royalties<span className="label-required">*</span>
                  </label>
                  <div className="input-suffix">
                    <AppInputAmount
                      className="form-control"
                      id="royalties"
                      decimalScale={0}
                      {...register("royalties", {
                        onChange: (e) => setValue("royalties", e.target.value),
                      })}
                    />
                    <div className="suffix">
                      <span>%</span>
                    </div>
                  </div>
                  <div className="error-message">
                    {errors?.royalties?.message}
                  </div>
                  <div className="hint hint-small">
                    Suggested: 0%, 10%, 20%, 30%. Maximum is 50%
                  </div>
                </Col>

                <Col span={12}>
                  <label htmlFor="amount">
                    Number of copies<span className="label-required">*</span>
                  </label>
                  <AppInputAmount
                    className="form-control"
                    id="amount"
                    decimalScale={0}
                    isAllowed={({ value }) => {
                      return value?.split(".")[0].length <= 5;
                    }}
                    {...register("amount", {
                      onChange: (e) => setValue("amount", e.target.value),
                    })}
                  />
                  <div className="error-message">{errors?.amount?.message}</div>
                  <div className="hint hint-small">Amount of tokens</div>
                </Col>
              </Row>
            </div>

            <div className="create-nft__form-group">
              <label htmlFor="tags">
                Hashtags<span className="label-required">*</span>
              </label>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => {
                  return (
                    <Select
                      mode="tags"
                      className="ant-select-customize-input tags"
                      dropdownClassName="custom-dropdown"
                      onChange={field.onChange}
                      tokenSeparators={[" "]}
                      value={field.value}
                      dropdownStyle={{ display: "none" }}
                    />
                  );
                }}
              />
              <div className="error-message">{errors?.tags?.message}</div>
            </div>

            <div className="create-nft__form-actions">
              <button className="btn-submit" type="submit">
                <span>Create item</span>
              </button>
            </div>
          </form>
        </Col>

        <Col xs={24} lg={6}>
          <div className="create-nft__preview">
            <h4>Preview</h4>
            <div className="create-nft__preview-file">
              {(filePreviewUrl &&
                (filePreviewCategory === "image" ||
                  filePreviewCategory === "video")) ||
              (filePreviewUrl &&
                fileCoverPreviewUrl &&
                filePreviewCategory !== "image" &&
                filePreviewCategory !== "video") ? (
                <div className="file">
                  {filePreviewCategory === "audio" && (
                    <img src={musicIcon} alt="" className="music-icon" />
                  )}
                  {filePreviewCategory === "video" ? (
                    <video width="100%" height="auto" autoPlay>
                      <source src={filePreviewUrl} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={fileCoverPreviewUrl || filePreviewUrl}
                      alt="file-preview"
                    />
                  )}
                  <div className="name">
                    <Tooltip title={watchAllFields.name}>
                      <p>{watchAllFields.name}</p>
                    </Tooltip>
                    <img src={usdtIcon} alt="eth-icon-img" />
                  </div>

                  <div className="price">
                    <img
                      src={selectedCollection?.image || hotdropIconImg}
                      alt="collection-img"
                    />
                    <p>
                      {watchAllFields.putOnMarketPlace
                        ? `${Number(watchAllFields.price) || "0"} USDT`
                        : "Not for sale"}
                    </p>
                  </div>
                </div>
              ) : (
                <p>Upload file to preview your brand new NFT</p>
              )}
            </div>

            {watchAllFields?.unlockOncePurchasedFlag && (
              <ReactMarkdown
                className="create-nft__preview-digital-key"
                children={
                  watchAllFields.unlockOncePurchased || "Unlockable content"
                }
              />
            )}
          </div>
        </Col>
      </Row>
      {isOpenCollectionModal && (
        <ListCollectionModal
          isOpen={isOpenCollectionModal}
          onDismis={() => setIsOpenCollectionModal(false)}
          collectionList={collections}
          collectionSelected={selectedCollection}
          setCollectionSelected={(collection, index) => {
            const newArr = arrayMove(collections, index, 0);
            setCollections(newArr);
            setSelectedCollection(collection);
            setIsOpenCollectionModal(false);
          }}
        />
      )}

      {showCreateCollectionModalFlag && (
        <CreateCollection
          visible={showCreateCollectionModalFlag}
          onDismiss={(isSuccess) => {
            setShowCreateCollectionModalFlag(false);
            if (isSuccess) {
              setRefreshCollections((prev) => !prev);
            }
          }}
        />
      )}
      <ActionsModal
        isOnlyAction={true}
        visible={showSuccessModalFlag}
        status={statusTx}
        title={"SUCCESS!"}
        message={
          statusTx === "pending"
            ? "NFT is being created. Please wait..."
            : "NFT has been created successfully."
        }
        onDismis={handleDismissSuccess}
        onConfirm={handleDismissSuccess}
      />
    </div>
  );
};

export default CreateNft;
