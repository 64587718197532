import { baseRequest } from './base-request';

const usersApi = {
    getUsers: (page = 1, size = 10, query = '') => {
        const endpoint = '/api/v1/admin/users';
        return baseRequest('get', endpoint, { page, size, query });
    },
    getUserById: (id: number) => {
        const endpoint = `/api/v1/admin/users/${id}`;
        return baseRequest('get', endpoint);
    },
    deleteUserById: (id: number) => {
        const endpoint = `/api/v1/admin/users/${id}`;
        return baseRequest('delete', endpoint);
    },
    activeUserById: (id: number) => {
        const endpoint = `/api/v1/admin/users/active/${id}`;
        return baseRequest('put', endpoint);
    },
    pauseUserById: (id: number) => {
        const endpoint = `/api/v1/admin/users/pause/${id}`;
        return baseRequest('put', endpoint);
    },
};

export default usersApi;
