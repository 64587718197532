import { Checkbox, Dropdown, Menu, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Filter } from '../types';
import filterIcon from '../../../assets/icons/filter.svg';

type Props = {
    filter: Filter;
    onFilter: (filters: string) => void;
    filterParams: string;
};

const FilterButton: React.FC<Props> = ({ filter, onFilter, filterParams }) => {
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);

    const handleVisibleChange = (flag: boolean) => {
        setVisible(flag);
    };

    const handleOptionChange = (e: any, value: string) => {
        let cloneData = [...(filterParams ? filterParams.split(",") : [])];
        if (e.target.checked) {
            cloneData.push(value);
        } else {
            cloneData = [...cloneData.filter((item) => item !== value)];
        }
        onFilter(cloneData.join(','));
    };

    useEffect(() => {
        if (!filterParams) return;
        const selected = filterParams.split(',');
        setSelected(selected);
    }, [filterParams])

    const menu = (
        <Menu>
            {filter.options?.map((option) => {
                return (
                    <Menu.Item key={option.value}>
                        <Checkbox
                            onChange={(e) => handleOptionChange(e, option.value)}
                            defaultChecked={selected.includes(option.value)}
                        >
                            {option.label}
                        </Checkbox>
                    </Menu.Item>
                )
            })}
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            placement="bottomRight"
        >
            <Space>
                <img src={filterIcon} alt="filter-icon" />
            </Space>
        </Dropdown>
    );
};

export default FilterButton;
