import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITransactionState {
  tokenInfo?: {
    [key: string]: {
      decimals?: number;
      symbol?: string;
    } | undefined;
  };
}

export const initialState: ITransactionState = {
  tokenInfo: undefined,
};

const _getTokenInfo: CaseReducer<
  ITransactionState,
  PayloadAction<{
    tokenAddress: string;
    abi: any;
    tokenInfo?: {
      decimals?: number;
      symbol?: string;
    };
  }>
> = (state, action) => {
  state.tokenInfo = {
    [action.payload.tokenAddress]: action.payload?.tokenInfo,
  };
};

const MasterSlice = createSlice({
  name: "MasterSlice",
  initialState,
  reducers: {
    getTokenInfo: _getTokenInfo,
  },
});

export const { getTokenInfo } = MasterSlice.actions;

export const masterActions = MasterSlice.actions;

export default MasterSlice.reducer;
