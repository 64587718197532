export enum FileTypeEnum {
    PNG = 'image/png',
    GIF = 'image/gif',
    WEBP = 'image/webp',
    MP3 = 'audio/mpeg',
    MP4 = 'video/mp4',
    JPG = 'image/jpg',
    JPEG = 'image/jpeg',
}

export const FileTypeLabels = {
    [FileTypeEnum.PNG]: 'png',
    [FileTypeEnum.GIF]: 'gif',
    [FileTypeEnum.WEBP]: 'webp',
    [FileTypeEnum.MP3]: 'mp3',
    [FileTypeEnum.MP4]: 'mp4',
    [FileTypeEnum.JPG]: 'jpg',
    [FileTypeEnum.JPEG]: 'jpeg',
};
