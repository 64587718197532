import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { PATH } from '../../constants/paths';
import { FaAngleDown } from 'react-icons/fa';
import logoImg from '../../assets/logo.png';
import { ReactComponent as GeneralStatIcon } from '../../assets/icons/general.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as MarketplaceIcon } from '../../assets/icons/marketplace.svg';
import { ReactComponent as NftIcon } from '../../assets/icons/nft.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';

type MenuItem = {
    key: string;
    title: string;
    path: string;
    icon: any;
    customClass?: string;
    childrens?: MenuItem[];
    matchPaths?: string[];
};

const menuItems: MenuItem[] = [
    {
        key: '1',
        title: 'General stat',
        path: PATH.GENERAL_STAT,
        icon: <GeneralStatIcon />,
        customClass: 'stroke',
        matchPaths: [''],
    },
    {
        key: '2',
        title: 'User management',
        path: '/users',
        icon: <UserIcon />,
        customClass: 'fill',
        matchPaths: ['users', 'creators'],
    },
    {
        key: '3',
        title: 'Marketplace',
        path: PATH.MARKETPLACE,
        icon: <MarketplaceIcon />,
        customClass: 'stroke',
        matchPaths: ['marketplace'],
    },
    {
        key: '4',
        title: 'NFT management',
        path: '/nfts',
        icon: <NftIcon />,
        customClass: 'fill',
        matchPaths: ['nfts'],
    },
    {
        key: '5',
        title: 'Badge management',
        path: '/badge',
        icon: <NftIcon />,
        customClass: 'fill',
        matchPaths: ['badge'],
    },
    {
        key: '6',
        title: 'Withdraw',
        path: '/withdraw',
        icon: <WalletIcon />,
        customClass: 'fill',
        matchPaths: ['withdraw'],
    },
];

type Props = {
    isCollapsed: boolean;
    isSideBarVisible: boolean;
    handleSideBar: () => void;
};

const SideBar: React.FC<Props> = ({
    isCollapsed,
    isSideBarVisible,
    handleSideBar,
}) => {
    const [selectedKey, setSelectedKey] = useState('');
    const [subMenuKey, setSubMenuKey] = useState('');
    const [selectedSubMenu, setSelectedSubMenu] = useState('');

    const selectMenu = (key: string) => {
        setSelectedKey(key);
        setSubMenuKey('');
        setSelectedSubMenu('');
        if (isSideBarVisible) {
            handleSideBar();
        }
    };

    const selectSubMenu = (menuKey: string, subKey: string) => {
        setSelectedKey(menuKey);
        setSelectedSubMenu(subKey);
        if (isSideBarVisible) {
            handleSideBar();
        }
    };

    useEffect(() => {
        let initKey = menuItems.find((menuItem) => {
            if (!menuItem?.childrens) {
                const pathname = window.location.pathname.split('/')[1];
                return menuItem.matchPaths?.some((matchPath) => matchPath === pathname);
            } else {
                let find = menuItem?.childrens?.find(
                    (item) => window.location.pathname === item.path
                );
                return find;
            }
        });
        if (initKey?.childrens) {
            setSelectedKey(initKey.key);
            setSubMenuKey(initKey?.key);
            setSelectedSubMenu(
                initKey?.childrens?.find(
                    (item) => window.location.pathname === item.path
                )?.key || ''
            );
        } else setSelectedKey(initKey?.key || '1');
    }, []);

    return (
        <nav
            className={
                isSideBarVisible
                    ? 'sidebar sidebar-offcanvas sidebar-active'
                    : isCollapsed
                    ? 'sidebar sidebar-offcanvas sidebar-collapsed'
                    : 'sidebar sidebar-offcanvas'
            }
        >
            <div className="sidebar-brand-wrapper fixed-top">
                <Link
                    to={PATH.GENERAL_STAT}
                    className={`sidebar-brand ${
                        isCollapsed ? 'collapsed' : ''
                    }`}
                    onClick={() => setSelectedKey('1')}
                >
                    <img src={logoImg} alt="logo-img" />
                </Link>
            </div>
            <ul className="nav">
                {menuItems &&
                    menuItems.map((menu) => {
                        return !menu?.childrens ? (
                            <li
                                className={
                                    selectedKey === menu.key
                                        ? 'nav-item menu-items active'
                                        : 'nav-item menu-items'
                                }
                                key={menu.key}
                            >
                                <Link
                                    to={menu.path}
                                    className="nav-link"
                                    onClick={() => selectMenu(menu.key)}
                                >
                                    <span
                                        className={`menu-icon ${
                                            menu.customClass || ''
                                        }`}
                                    >
                                        {menu.icon}
                                    </span>
                                    <span className="menu-title">
                                        <span>{menu.title}</span>
                                    </span>
                                </Link>
                            </li>
                        ) : (
                            <li
                                className={
                                    selectedKey === menu.key
                                        ? 'nav-item menu-items active'
                                        : 'nav-item menu-items'
                                }
                                key={menu.key}
                            >
                                <div
                                    className="nav-link"
                                    onClick={() =>
                                        subMenuKey === menu.key
                                            ? setSubMenuKey('')
                                            : setSubMenuKey(menu.key)
                                    }
                                >
                                    <span className="menu-icon">
                                        {menu.icon}
                                    </span>
                                    <span className="menu-title">
                                        {menu.title}
                                    </span>
                                    <FaAngleDown className="menu-arrow" />
                                </div>
                                <div
                                    className={
                                        subMenuKey === menu.key
                                            ? 'collapsed-menu show'
                                            : 'collapsed-menu'
                                    }
                                >
                                    <ul className="sub-menu">
                                        {menu?.childrens?.map((subMenu) => (
                                            <li
                                                className="nav-item"
                                                key={subMenu.key}
                                            >
                                                <Link
                                                    to={subMenu.path}
                                                    className={
                                                        selectedSubMenu ===
                                                        subMenu.key
                                                            ? 'nav-link-sub active'
                                                            : 'nav-link-sub'
                                                    }
                                                    onClick={() =>
                                                        selectSubMenu(
                                                            menu.key,
                                                            subMenu.key
                                                        )
                                                    }
                                                >
                                                    {subMenu.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
};

export default SideBar;
