/* eslint-disable import/no-anonymous-default-export */
import { baseRequest } from "../base-request";

export default {
  login: async (credentials: any) => {
    let url = "/api/v1/admin/auth/login";
    const res = await baseRequest("post", url, credentials);
    return res;
  },
  getUserProfile: async () => {
    let url = "/api/v1/admin/auth/me";
    const res = await baseRequest("get", url);
    return res;
  },
  saveWalletAdmin: async ({ address }: { address: string }) => {
    let url = "/api/v1/admin/save-wallet";
    const res = await baseRequest("post", url, { address });
    return res;
  },
};

