/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { baseRequest } from '../api/base-request';

type CustomTable = {
    page: number;
    size: number;
    totalRow: number;
    totalPage: number;
    rows: any[];
};

const initialTable: CustomTable = {
    page: 1,
    size: 10,
    totalRow: 0,
    totalPage: 0,
    rows: [],
};

const useTableFetch = (endpoint: string, tableOptions?: any) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({...initialTable, page: tableOptions?.page || 1});
    const [query, setQuery] = useState(tableOptions?.query || '');
    const [params, setParams] = useState<any>(tableOptions?.params || {});

    const fetchData = async (page = 1, size = 10) => {
        try {
            setIsLoading(true);
            const {
                data: { data },
            } = await baseRequest('get', endpoint, null, {
                page,
                size,
                query,
                ...params,
            });

            setState(data || initialTable);
            setIsLoading(false);
            setIsFirstLoad(false);
        } catch (error) {
            setState(initialTable);
            setIsLoading(false);
            setIsFirstLoad(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(state.page, state.size);
    }, [state.page, state.size]);

    useEffect(() => {
        if (isFirstLoad) {
            return;
        }

        setState((prevState) => ({ ...prevState, page: 1 }));
        fetchData(1);
    }, [query, params, endpoint]);

    return {
        state,
        setState,
        query,
        setQuery,
        isLoading,
        params,
        setParams,
    };
};

export default useTableFetch;
