import React, { useEffect, useState } from 'react';
import { StatusEnum, StatusLabels } from '../../constants/Status';
import './styles.scss';
import dropdownIcon from '../../assets/icons/dropdown.svg';
import { Dropdown, Menu, Space } from 'antd';

type Props = {
    status: StatusEnum;
    editable?: boolean;
    customClass?: string;
    statusList?: StatusEnum[];
    onChange?: (status: StatusEnum) => void;
};

const Status: React.FC<Props> = ({
    status,
    editable,
    customClass,
    statusList,
    onChange
}) => {
    const [options, setOptions] = useState<StatusEnum[]>();

    useEffect(() => {
        setOptions(statusList?.filter((item) => item !== status));
    }, [status, statusList]);

    const menu = (
        <Menu>
            {options?.map((option) => (
                <Menu.Item
                    key={option}
                    onClick={() => onChange && onChange(option)}
                >
                    {StatusLabels[option]}
                </Menu.Item>
            ))}
        </Menu>
    );

    const getColor = () => {
        switch (status) {
            case StatusEnum.ACTIVE:
            case StatusEnum.VERIFIED:
            case StatusEnum.SALE:
                return '#10B981';
            case StatusEnum.PAUSED:
            case StatusEnum.NOT_VERIFIED:
            case StatusEnum.HIDDEN:
                return '#FF3E3E';
            default:
                return;
        }
    };

    const getBgColor = () => {
        switch (status) {
            case StatusEnum.ACTIVE:
                return '#10B98133';
            case StatusEnum.PAUSED:
                return '#FF3E3E33';
            default:
                return;
        }
    };

    return (
        <>
            {editable ? (
                <Dropdown overlay={menu} trigger={['click']} placement="bottom">
                    <Space>
                        <div
                            className={`custom-status ${customClass}`}
                            style={{
                                color: getColor(),
                                background: getBgColor(),
                            }}
                        >
                            {StatusLabels[status]}
                            {editable && (
                                <img src={dropdownIcon} alt="dropdown-icon" />
                            )}
                        </div>
                    </Space>
                </Dropdown>
            ) : (
                <div
                    className={`custom-status ${customClass}`}
                    style={{
                        color: getColor(),
                        background: getBgColor(),
                    }}
                >
                    {StatusLabels[status]}
                </div>
            )}
        </>
    );
};

export default Status;
