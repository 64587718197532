/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import ActionButton from "../../components/ActionButton";
import ActionsModal from "../../components/ActionsModal";
import AppInput from "../../components/Base/AppInput";
import "./styles.scss";

const Withdraw: React.FC = () => {
  const [balance] = useState<string>("0");
  const [inputAmount, setInputAmount] = useState<string>("");

  const [isOpenModalWithdraw, setIsOpenModalWithdraw] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleWithdraw = useCallback(() => {
    // dispatch(
    //   transactionActions.withdrawFormAdminManager({ amount: inputAmount })
    // );
  }, [dispatch, inputAmount]);

  return (
    <div className="witdraw-royalty-page">
      <h1>Withdraw</h1>
      <div>
        <div className="balance">Balance: {balance}</div>
        <Row align="middle" className="amount-form">
          <Col className="label">Amount: </Col>
          <Col className="amount">
            <AppInput
              value={inputAmount}
              onChange={(e: any) => setInputAmount(e.target.value)}
            />
          </Col>
        </Row>
        <ActionButton
          className="btn-withdraw"
          actionName="Withdraw"
          callback={() => setIsOpenModalWithdraw(true)}
        />
      </div>
      {
        isOpenModalWithdraw && (
          <ActionsModal
            visible={isOpenModalWithdraw}
            onDismis={() => setIsOpenModalWithdraw(false)}
            title={"TRANSACTION CONFIRMATION"}
            message={
              "Are you sure you want to withdraw 7749 ETH?"
            }
            onConfirm={() => {
              setIsOpenModalWithdraw(false);
              handleWithdraw();
            }}
          />
        )
      }
    </div>
  );
};

export default Withdraw;
