import React from 'react';
import './styles.scss';

export default function Trending(props: any) {
    const { data } = props;

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th className="col-sm"></th>
                        <th className="col-lg">Trending search terms</th>
                        <th className="col-lg">Trending Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data?.map((item: any, index: number) => (
                        <tr key={index}>
                            <td className="col-sm">{index + 1}</td>
                            <td className="col-lg">{item?.search}</td>
                            <td className="col-lg">{item?.tag}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
