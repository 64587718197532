import { baseRequest } from './base-request';
import axios from 'axios';

const cloudinaryEndpoint: any = process.env.REACT_APP_CLOUDINARY_API;

const creatorsApi = {
    getCreators: (page = 1, size = 10, query = '') => {
        const endpoint = '/api/v1/admin/creators';
        return baseRequest('get', endpoint, { page, size, query });
    },
    getAllCreators: () => {
        const endpoint = '/api/v1/admin/creators/list/all';
        return baseRequest('get', endpoint);
    },
    getCreatorById: (id: number) => {
        const endpoint = `/api/v1/admin/creators/${id}`;
        return baseRequest('get', endpoint);
    },
    deleteCreatorById: (id: number) => {
        const endpoint = `/api/v1/admin/creators/${id}`;
        return baseRequest('delete', endpoint);
    },
    activeCreatorById: (id: number) => {
        const endpoint = `/api/v1/admin/creators/active/${id}`;
        return baseRequest('put', endpoint);
    },
    pauseCreatorById: (id: number) => {
        const endpoint = `/api/v1/admin/creators/pause/${id}`;
        return baseRequest('put', endpoint);
    },
    createCreator: (data: any) => {
        const endpoint = '/api/v1/admin/creators';
        return baseRequest('post', endpoint, data);
    },
    uploadImage: async (file: any) => {
        return await axios.post(cloudinaryEndpoint, file);
    },
};

export default creatorsApi;
