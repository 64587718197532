import React from 'react';
import Loading from '../Loading';
import './styles.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    actionName: string;
    isLoading?: boolean;
    callback: () => void;
};

const ActionButton: React.FC<Props> = ({ actionName, isLoading, callback, className, ...props }) => {
    return (
        <button
            className={`action-button ${className}`}
            onClick={callback}
            disabled={isLoading}
            {...props}
        >
            <span>{actionName}</span>
            {isLoading && <Loading />}
        </button>
    );
};

export default ActionButton;
