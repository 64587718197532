import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { MdPhotoCamera } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import creatorsApi from "../../../api/creators";
import DatePickerIcon from "../../../assets/icons/date-picker-icon.svg";
import defaultAvatar from "../../../assets/images/default-avatar-creator.png";
import ActionsModal from "../../../components/ActionsModal";
import { AppInputAmount } from "../../../components/Base";
import { DEFAULT_CREATOR_AVATAR } from "../../../constants/title";
import { toastError } from "../../../layouts/MainLayout/slice";
import CropImageModal from "../CropImageModal";
import { createCreatorSchema } from "./schema";
import "./styles.scss";

const genders = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Other", value: "other" },
];

const relations = [
  { name: "Single", value: "single" },
  { name: "Married", value: "married" },
];

const signs = [
  { name: "Capricorn", value: "capricorn" },
  { name: "Aquarius", value: "aquarius" },
  { name: "Pisces", value: "pisces" },
  { name: "Aries", value: "aries" },
  { name: "Taurus", value: "taurus" },
  { name: "Gemini", value: "gemini" },
  { name: "Cancer", value: "cancer" },
  { name: "Leo", value: "leo" },
  { name: "Virgo", value: "virgo" },
  { name: "Libra", value: "libra" },
  { name: "Scorpio", value: "scorpio" },
  { name: "Sagitarius", value: "sagitarius" },
];

export default function CreateCreator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCreatorSchema),
  });
  const datePickerRef = useRef<any>();
  const [avatar, setAvatar] = useState<any>();
  const [background, setBackground] = useState<any>();
  const [isAvatarEdit, setIsAvatarEdit] = useState(false);
  const [isBackgroundEdit, setIsBackgroundEdit] = useState(false);
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusTx, setStatusTx] = useState<'pending' | 'success' | 'error'>();

  const handleAvatarEditCancel = () => {
    setIsAvatarEdit(false);
  };

  const handleBackgroundEditCancel = () => {
    setIsBackgroundEdit(false);
  };

  const updateAvatar = async (croppedImage: any) => {
    if (!croppedImage?.file) {
      setAvatar(undefined);
    } else {
      const isCroppedImageSizeAllow = checkFileSize(croppedImage?.file);
      if (!isCroppedImageSizeAllow) {
        setAvatar(undefined);
        dispatch(
          toastError({ message: "The file must not be larger than 50MB!" })
        );
        handleAvatarEditCancel();
      } else {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", croppedImage?.file);
        formData.append("upload_preset", "cloudinary_preset");
        const res = await creatorsApi.uploadImage(formData);
        if (res.status === 200) {
          setAvatar(res.data);
          setIsLoading(false);
          handleAvatarEditCancel();
        } else {
          setIsLoading(false);
          toast.error("Something went wrong when upload image");
        }
      }
    }
  };

  const updateBackground = async (croppedImage: any) => {
    if (!croppedImage?.file) {
      setBackground(undefined);
    } else {
      const isCroppedImageSizeAllow = checkFileSize(croppedImage?.file);
      if (!isCroppedImageSizeAllow) {
        setBackground(undefined);
        dispatch(
          toastError({ message: "The file must not be larger than 50MB!" })
        );
        handleBackgroundEditCancel();
      } else {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", croppedImage?.file);
        formData.append("upload_preset", "cloudinary_preset");
        const res = await creatorsApi.uploadImage(formData);
        if (res.status === 200) {
          setBackground(res.data);
          setIsLoading(false);
          handleBackgroundEditCancel();
        } else {
          setIsLoading(false);
          toast.error("Something went wrong when upload image");
        }
      }
    }
  };

  const checkFileSize = (file: any) => {
    const fileSize = file?.size / 1024 / 1024;
    const isAllowSize = fileSize < 50;
    return isAllowSize;
  };

  const submitCreator = async (data: any) => {
    try {
      setStatusTx('pending');
      setIsOpenSuccessPopup(true);
      let inputData = {
        ...removeNullObject(data),
        avatar: avatar?.url || DEFAULT_CREATOR_AVATAR,
        avatarSecure: avatar?.secure_url || DEFAULT_CREATOR_AVATAR,
        image: background?.url,
        imageSecure: background?.secure_url,
        birthday: moment(data.birthday).format("YYYY-MM-DD"),
        joinedHotdrops: moment(data.joinedHotdrops).format("YYYY-MM-DD"),
        fakeBoobs: Number(data.fakeBoobs),
        commission: Number(data.commission),
      };
      const res = await creatorsApi.createCreator(inputData);

      if (res?.status === 201) {
        setStatusTx('success');
        return;
      }

      toast.error("Something went wrong");
    } catch (err) {
      dispatch(toastError({ message: (err as any)?.response.data.message }));
      setIsOpenSuccessPopup(false);
      setStatusTx('error');
      console.log("error", err);
    }
  };

  const removeNullObject = (params: any) => {
    return Object.fromEntries(
      Object.entries(params)
        .filter(([_, v]) => v)
        .map(([_, v]) => {
          if (typeof v === "string") {
            return [_, v.trim()];
          }

          return [_, v];
        })
    );
  };

  const checkSpaceInput = (e: any) => {
    if (e.which === 32) {
      e.preventDefault();
      return false;
    }
  };

  const handleCloseSuccess = () => {
    navigate("/users", { state: true });
  };

  return (
    <>
      <div className="create-creator-page">
        <div className="page-header">
          <FiArrowLeft
            className="icon-back"
            onClick={() => navigate("/users", { state: true })}
          />
          Create Creators
        </div>
        <div className="page-row">
          <div className="col-left">
            <form onSubmit={handleSubmit(submitCreator)}>
              <div className="form-container">
                <div className="form-title">Basic Info</div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">
                      User Name <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <input
                        {...register("username")}
                        type="text"
                        maxLength={30}
                        onKeyPress={checkSpaceInput}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.username?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">
                      Password <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <input
                        {...register("password")}
                        type="password"
                        maxLength={16}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.password?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">
                      First Name <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <input
                        {...register("firstName")}
                        type="text"
                        maxLength={30}
                        style={{ textTransform: "capitalize" }}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.firstName?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">
                      Last Name <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <input
                        {...register("lastName")}
                        type="text"
                        maxLength={30}
                        style={{ textTransform: "capitalize" }}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.lastName?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">
                      Email <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <input
                        {...register("email")}
                        type="text"
                        maxLength={100}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Join HotDrop from</div>
                    <div className="form-item__input">
                      <input
                        {...register("joinedHotdrops")}
                        type="text"
                        disabled
                        value={moment().format("MM/DD/YYYY")}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.joinedHotdrops?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">
                      Birthday <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <img
                        src={DatePickerIcon}
                        alt=""
                        className="prefix-icon"
                        onClick={() => datePickerRef.current.setFocus(true)}
                      />
                      <Controller
                        control={control}
                        name="birthday"
                        render={({ field }) => (
                          <ReactDatePicker
                            ref={datePickerRef}
                            className="date-picker"
                            onChange={(e) => field.onChange(e)}
                            selected={field.value}
                            dateFormat="MM/dd/yyyy"
                            showPopperArrow={false}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={new Date()}
                            placeholderText="Please choose a birthday"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.birthday?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Birthplace</div>
                    <div className="form-item__input">
                      <input
                        {...register("birthPlace")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.birthPlace?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">
                      Commission <span className="label-required">*</span>
                    </div>
                    <div className="form-item__input">
                      <AppInputAmount
                        decimalScale={0}
                        className="input-with-suffix"
                        {...register("commission", {
                          onChange: (e) =>
                            setValue("commission", e.target.value),
                        })}
                      />
                      <span className="input-suffix">%</span>
                    </div>
                    <div className="form-item__error">
                      {errors?.commission?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">KYC Status</div>
                    <div className="form-item__input">
                      <select {...register("kycStatus")}>
                        <option value="verified">Verified</option>
                        <option value="not_verified">Not verified</option>
                      </select>
                    </div>
                    <div className="form-item__error">
                      {errors?.kycStatus?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Twitter URL</div>
                    <div className="form-item__input">
                      <input
                        {...register("twitterUrl")}
                        type="text"
                        maxLength={100}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.twitterUrl?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Bio</div>
                    <div className="form-item__input">
                      <textarea {...register("bio")} maxLength={2000} />
                    </div>
                    <div className="form-item__error">
                      {errors?.bio?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-container">
                <div className="form-title">Others</div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Gender</div>
                    <div className="form-item__input">
                      <select {...register("gender")}>
                        <option value="">Choose a gender</option>
                        {genders.map((gender) => (
                          <option value={gender.value} key={gender.value}>
                            {gender.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-item__error">
                      {errors?.gender?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Relationship status</div>
                    <div className="form-item__input">
                      <select {...register("relationshipStatus")}>
                        <option value="">Choose a status</option>
                        {relations.map((relation) => (
                          <option value={relation.value} key={relation.value}>
                            {relation.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-item__error">
                      {errors?.relationshipStatus?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Measurement</div>
                    <div className="form-item__input">
                      <input
                        {...register("measurements")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.measurements?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Career Status</div>
                    <div className="form-item__input">
                      <input
                        {...register("careerStatus")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.careerStatus?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Sign</div>
                    <div className="form-item__input">
                      <select {...register("sign")}>
                        <option value="">Choose a sign</option>
                        {signs.map((sign) => (
                          <option value={sign.value} key={sign.value}>
                            {sign.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-item__error">
                      {errors?.sign?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Orientation</div>
                    <div className="form-item__input">
                      <input
                        {...register("orientation")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.orientation?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Ethinicity</div>
                    <div className="form-item__input">
                      <input
                        {...register("ethnicity")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.ethnicity?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Interested</div>
                    <div className="form-item__input">
                      <input
                        {...register("interested")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.interested?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Height</div>
                    <div className="form-item__input">
                      <input
                        {...register("height")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.height?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Weight</div>
                    <div className="form-item__input">
                      <input
                        {...register("weight")}
                        type="text"
                        className="input-with-suffix"
                        maxLength={5}
                      />
                      <div className="input-suffix">LBS</div>
                    </div>
                    <div className="form-item__error">
                      {errors?.weight?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Hair color</div>
                    <div className="form-item__input">
                      <input
                        {...register("hairColor")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.hairColor?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Eye color</div>
                    <div className="form-item__input">
                      <input
                        {...register("eyeColor")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.eyeColor?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Fake boobs</div>
                    <div className="form-item__input">
                      <select {...register("fakeBoobs")}>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div className="form-item__error">
                      {errors?.fakeBoobs?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Tatoos</div>
                    <div className="form-item__input">
                      <input
                        {...register("tattoos")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.tattoos?.message}
                    </div>
                  </div>
                </div>
                <div className="row-item">
                  <div className="form-item">
                    <div className="form-item__label">Piercings</div>
                    <div className="form-item__input">
                      <input
                        {...register("piercings")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.piercings?.message}
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="form-item__label">Background</div>
                    <div className="form-item__input">
                      <input
                        {...register("background")}
                        type="text"
                        maxLength={200}
                      />
                    </div>
                    <div className="form-item__error">
                      {errors?.background?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-container">
                <div className="row-item">
                  <div className="form-item group-btn">
                    <button
                      className="btn-cancel"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/users", { state: true });
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn-submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-right">
            <div className="col-title">Profile</div>
            <div className="profile-item">
              <div
                className="profile-item__avatar"
                onClick={() => setIsAvatarEdit(true)}
              >
                {avatar?.url ? (
                  <img src={avatar?.url} alt="" />
                ) : (
                  <img src={defaultAvatar} alt="" />
                )}
                <div className="camera-overlay">
                  <MdPhotoCamera className="icon-camera" />
                </div>
              </div>
              <div className="profile-item__label">Upload Avatar</div>
            </div>
            <div className="profile-item">
              <div
                className="profile-item__background"
                onClick={() => setIsBackgroundEdit(true)}
              >
                {background?.url && <img src={background?.url} alt="" />}
                <div className="camera-overlay">
                  <MdPhotoCamera className="icon-camera" />
                </div>
              </div>
              <div className="profile-item__label">Upload Background</div>
            </div>
          </div>
        </div>
      </div>
      <CropImageModal
        isModalVisible={isAvatarEdit}
        handleCancel={handleAvatarEditCancel}
        type="avatar"
        updateImage={updateAvatar}
        isLoading={isLoading}
      />
      <CropImageModal
        isModalVisible={isBackgroundEdit}
        handleCancel={handleBackgroundEditCancel}
        type="background"
        updateImage={updateBackground}
        isLoading={isLoading}
      />
      <ActionsModal
        visible={isOpenSuccessPopup}
        isOnlyAction={true}
        status={statusTx}
        message={
          statusTx === 'pending'
          ? 'New creator is being created. Please wait...'
          : "New creator added successfully. A notification email has been sent to the user"
        }
        title={"SUCCESS!"}
        onConfirm={handleCloseSuccess}
        onDismis={handleCloseSuccess}
      />
    </>
  );
}
