import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import authApi from '../../api/auth';
import { toast } from 'react-toastify';

interface IAuthSate {
    currentUser: any,
    isLoading: boolean,
    errorMessage: string,
    isConnected: boolean,
}

const initialState: IAuthSate = {
    currentUser: {},
    isLoading: false,
    errorMessage: '',
    isConnected: false,
};

export const login = createAsyncThunk('auth/login', async (payload: { email: string, password: string, onSuccess: any }) => {
    try {
        const data = {
            email: payload.email,
            password: payload.password
        }
        const res = await authApi.login(data);
        if (res?.status === 201) {
            localStorage.setItem('access_token', res?.data?.data?.accessToken);
            const resUser = await authApi.getUserProfile();
            toast.success('Login Successful!');
            payload.onSuccess();
            return resUser;
        }
        return res;
    }
    catch ({ response }) {
        return response as any;
    }
})

const _connectWallet: CaseReducer<
IAuthSate,
    PayloadAction<{ isConnected: boolean }>
> = (state, action) => {
    state.isConnected = action.payload.isConnected;
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: (state, action) => {
            state.currentUser = {};
            state.errorMessage = '';
            localStorage.removeItem('access_token');
            localStorage.removeItem('last_active');
            action.payload?.onSuccess();
        },
        clearError: (state) => {
            state.errorMessage = '';
        },
        connectWallet: _connectWallet,
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload?.status === 201 || action.payload?.status === 200) {
                    state.currentUser = action.payload?.data?.data;
                    state.errorMessage = ''
                }
                else {
                    state.errorMessage = action.payload?.data?.message;
                }
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                console.log('login rejected', action);
            })
    }
})

export const { logOut, clearError, connectWallet } = AuthSlice.actions;

export default AuthSlice.reducer;
