import React from "react";
import CustomModal from "../CustomModal";
import "./styles.scss";
import closeIcon from "../../assets/icons/close.svg";

type Props = {
  visible: boolean;
  onDismis: () => void;
  title: string;
  message: string;
  status?: "error" | "pending" | "success";
  onConfirm: () => void;
  isOnlyAction?: boolean;
};

const ActionsModal: React.FC<Props> = ({
  visible,
  onDismis,
  title,
  message,
  onConfirm,
  isOnlyAction,
  status = "success",
}) => {
  return (
    <CustomModal
      visible={visible}
      onDismis={onDismis}
      width={400}
      customClass={`confirm-modal ${
        status === "pending" ? "action-pending-modal" : ""
      }`}
    >
      {status !== "pending" && (
        <div className="confirm-modal__header">
          <h3>{title}</h3>
          {!isOnlyAction && (
            <img src={closeIcon} alt="close-icon" onClick={onDismis} />
          )}
        </div>
      )}
      {status === "pending" && <div className="confirm-modal__icon-loading" />}

      <div
        className={`confirm-modal__content ${
          status === "pending" ? "mt-24 text-center" : "my-20"
        }`}
      >
        {message}
      </div>

      {status !== "pending" && (
        <div
          className={`confirm-modal__btn-actions ${
            isOnlyAction && "only-action"
          }`}
        >
          {!isOnlyAction && (
            <button className="btn-cancel" onClick={onDismis}>
              Cancel
            </button>
          )}
          <button className="btn-confirm" onClick={onConfirm}>
            OK
          </button>
        </div>
      )}
    </CustomModal>
  );
};

export default ActionsModal;
