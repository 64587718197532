import * as yup from "yup";

export const collectionSchema = yup.object().shape({
  name: yup.string().test({ test: (val) => !!val?.trim(), message: "This field is required" }),
  symbol: yup.string().test({ test: (val) => !!val?.trim(), message: "This field is required" }),
  shortUrl: yup
    .string()
    .required("This field is required.")
    .test({
      test: (val) => /^[A-Za-z0-9_-]*$/.test(val as string),
      message:
        'URL must only contain alphanumeric characters and special characters"-", "_"',
    }),
});
