import React from "react";
import CustomModalOrange from "../../../components/CustomModalOrange";
import "./styles.scss";

const ListCollectionModal = ({
  collectionList,
  collectionSelected,
  setCollectionSelected,
  isOpen,
  onDismis,
}: {
  collectionList: any[];
  collectionSelected: any;
  setCollectionSelected: (collection: any, index: number) => void;
  isOpen: boolean;
  onDismis: () => void;
}) => {

  return (
    <CustomModalOrange visible={isOpen} onDismis={onDismis} title="COLLECTION" className="collection-list-modal">
      <div className="collections">
        {
          collectionList.map((collection: any, index: number) => (
            <div
              key={collection.id}
              className={`collections__item${
                collectionSelected?.id === collection.id
                  ? " collections__item--selected"
                  : ""
              }`}
            >
              <div
                className="inner"
                onClick={() => {
                  setCollectionSelected(collection, index);
                }}
              >
                <img src={collection.image} alt="hotdrop-icon-img" />
                <p title={collection.name}>{collection.name}</p>
              </div>
            </div>
          ))
        }
      </div>
    </CustomModalOrange>
  )
};

export default ListCollectionModal;
