import React from "react";
import "./styles.scss";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

export default function DoughnutChart(props: any) {
  const { data, className } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: true,
      position: "right",
      labels: {
        padding: 30,
        boxWidth: 16,
        fontColor: "#ACACAC",
        fontSize: 16,
        fontFamily: "Montserrat",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: true,
    },
  };

  return (
    <div className={`doughnut-chart-container ${className}`}>
      {data.datasets[0].data.filter((item: string) => Number(item)).length ? (
        <>
          <Doughnut data={data} options={options} />
          <div className="chart-title">
            Gross
            <br />
            Profits
          </div>
        </>
      ) : (
        <div className="no-data-container">No data</div>
      )}
    </div>
  );
}
